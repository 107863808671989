.lp-section-card-product {
  background-color: #dfdfdf;
  position: relative;
  background-attachment: fixed;
  background-size: contain;
  padding-bottom: 120px;
  background-position: left;
  // @TODO @ID: img-bottom-right-fix @Bruce: Das wurde hinzugefügt, weil img-bottom-right sonst in den nächsten Titel hineinragt
  margin-top: 40px;
  margin-bottom: 120px;
  @include media-breakpoint-up(lg) {
    padding-bottom: 104px;
    // @TODO @ID: img-bottom-right-fix
    margin-top: 0;
    margin-bottom: 0;
  }
  @include media-breakpoint-up(xl) {
    background-size: cover;

  }
  @media (min-width: 1440px) {
    padding-bottom: 160px;
  }
  &:focus, .flip-card:focus {
    outline: none;
  }
  .container:first-of-type {
    padding-top: 120px;
    @media (min-width: 1440px) {
      padding-top: 160px;
    }
  }

  //.card-extra-top-spacer {
  //  // TODO: @ID: img-bottom-right-fix
  //  height: 40px;
  //  background-color: #FFFFFF;
  //  position: absolute;
  //  top: -40px;
  //  left: 0;
  //  width: 100%;
  //  display: block;
  //  @include media-breakpoint-up(lg) {
  //    display: none;
  //  }
  //}
  //
  //.card-extra-bottom-spacer {
  //  // TODO: @ID: img-bottom-right-fix
  //  height: 40px;
  //  background-color: #FFFFFF;
  //  position: absolute;
  //  bottom: -40px;
  //  left: 0;
  //  width: 100%;
  //  display: block;
  //  @include media-breakpoint-up(lg) {
  //    display: none;
  //  }
  //}

  // TODO: @Bruce: Default steht auf z-index: -1. Warum, keine Ahnung. Ich hab's hier mal ausgehebelt.
  .img-middle-left {
    z-index: 1;
  }

  // // Intro
  // // -------------------------------------------------------------------------------------------------------------------
  // .intro {
  //   //margin-bottom: 32px;
  //   text-align: center;
  //   @include make-row();
  //   //@include media-breakpoint-up(md) {
  //   //  margin-bottom: 80px;
  //   //}
  //
  //   .lead {
  //     margin: 0 auto;
  //   }
  // }

  // Content
  // -------------------------------------------------------------------------------------------------------------------
  .container-fluid {
    position: relative;
    z-index: 1;
    // TODO @Bruce Und DAS ist der fix, weil 2x Container fluid eingebunden wurde und img-middle-left über dem zweiten liegt :(
    z-index: 2;
    padding-top: 32px;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      padding-top: 80px;
    }
    //overflow: hidden;
    //overflow: hidden;
    //overflow: ;


    + .container > .row:last-of-type {
      text-align: center;
      justify-content: center;
    }

    //~ .img-middle-left ~ .container {
    //  margin-top: 40px;
    //  @include media-breakpoint-up(lg) {
    //    margin-top: 120px;
    //  }
    //}

    // Slick Slider
    // ---------------------------------------------------------------

    //@include media-breakpoint-up(sm) {
    //  margin-left: calc((100vw - 720px) / 2);
    //  width: calc(100vw - ((100vw - 720px) / 2));
    //}
    @include media-breakpoint-only(md) {
      //margin-left: calc((100vw - 720px) / 2);
      //width: calc(100vw - ((100vw - 720px) / 2));
      padding-left: calc(((100vw - 720px) / 2) + 12px);
      padding-right: 0;
      > .row {
        margin: 0 -10px;
      }
    }
    @include media-breakpoint-only(lg) {
      //margin-left: calc((100vw - 960px) / 2);
      margin: 0;
      //width: calc(100vw - ((100vw - 960px) / 2));
      padding-left: calc(((100vw - 960px) / 2) + 12px);
      //padding-right: 0;
      //> .row {
      //  margin: 0 -10px;
      //}
      //margin: 0 auto;
      //max-width: 1464px;
    }
    @include media-breakpoint-up(xl) {
      margin: 0 auto;
      width: 100%;
      max-width: 1464px;
    }
    .lp-section-card-product-slider {
      padding-bottom: 52px;
      @include media-breakpoint-up(xl) {
        @include make-row();
        padding-bottom: 40px;
        //justify-content: center;
        &.justify-content {
          justify-content: center;
        }
        &.three-columns {
          flex: 0 0 75%;
          max-width: 75%;
          margin: 0 auto;
          .flip-card {
            flex: 0 0 33.33333%;
          }
        }
      }

      .slick-track {
        display: flex;
      }

      .slick-list {
        overflow: visible;
      }

      //.slick-list {
      //  overflow: visible;
      //}
      //.slick-track {
      //  display: flex;w
      //}

    }
  }

  .container-fluid .row {
    .col-12 {
      @include media-breakpoint-down(lg) {
        width: 720px;
        max-width: 720px;
        padding: 0;
      }
      @include media-breakpoint-down(md) {
        width: 640px;
        max-width: 640px;
        padding: 0;
      }
      @include media-breakpoint-down(sm) {
        margin-left: calc(((100vw - 540px) / 2) + 2px);
        width: 320px;
        max-width: 320px;
        padding: 0;
      }
      @include media-breakpoint-down(xs) {
        margin-left: 14px;
      }
    }
  }

  // dots
  .slick-dots {
    left: -14px;
    @include media-breakpoint-up(sm) {
      left: calc((100vw - 540px) / -2);
      left: -14px;
      left: calc(((100vw - 540px) / -2) - 2px);
    }
    @include media-breakpoint-up(md) {
      left: calc(((100vw - 720px) / -2) - 2px);
    }
    @include media-breakpoint-up(lg) {
      left: calc(((100vw - 960px) / -2) - 0px);
    }
  }


  // Footer
  // -------------------------------------------------------------------------------------------------------------------
  .btn-lg {
    //margin-top: 40px;
    background-color: $blue;
    border: $blue;
    &:hover, &:active, &:focus {
      background-color: darken($blue, 5%)
    }
  }
}
