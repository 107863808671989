.search-form {
    .input-group-text,
    input {
        border-radius: 0;
        background-color: $lighterblue;
        border: 0;
        height: 56px;
        border-bottom: 2px solid #c8dff2;
        letter-spacing: 0.3px;
        font-weight: $font-weight-bold;
        transition: $transition-base;
        background-clip: border-box;
    }

    .input-group-text {
        padding: 0 20px;
    }

    input {
        padding-left: 0;
        // &[type='search'] {
        //     /* Remove default */
        //     -webkit-appearance: none;
        //     /* Now your own custom styles */
        //     height: 10px;
        //     width: 10px;
        //     background: red;
        //     /* Will place small red box on the right of input (positioning carries over) */
        // }
        &[type="search"]::-webkit-search-cancel-button {
            /* Remove default */
            -webkit-appearance: none;
            /* Now your own custom styles */
            height: 20px;
            width: 20px;
            // background: ;
            // border-radius: 50%;
            // border: 2px solid $primary;
            /* Will place small red box on the right of input (positioning carries over) */
            background: icon(icon-clear, $primary) no-repeat;
            background-size: 20px;
            // background: red;
            cursor: pointer;
        }
    }

    .focus {
        .input-group-text, .search-field {
            border-color: $secondary;
            //transition: $transition-base;
            transition: all 0s;
        }
    }
}

form {
    label {
        position: absolute;
        // top: 1.1rem;
        left: 16px;
        display: block;
        // padding-left: 0.4rem;
        // padding-right: 0.4rem;
        font-size: 18px;
        // background: white;
        transition: $transition-base;
        color: #333;
        // will-change: top, font-size;
        z-index: 10;
        top: 36px;
        font-weight: $font-weight-bold;

        &:hover {
            cursor: text;
        }
    }

    input {
        width: 100%;
        // padding: 16px;
        // margin: 26px 0;
        background: transparent;
        border: 2px solid rgba(0,0,0,0);
        border-color: #e6e6e6;
        transition: $transition-base;
        // will-change: border-color;
        // &:focus {
        // 	outline: none;
        //     top: -40px;
        // 	&:valid { border-color: $green; }
        // }
        // &:valid { border-color: $green; }
        &[type="password"] {
            font-family: Courier, monospace;
        }
        // &:first-of-type { margin-top: 0; }
    }

    .input-group {
        // position: relative;
        &.focus label,
        &.has-input label {
            top: -8px;
            line-height: 24px;
            font-size: 16px;
        }
    }

    .input-group-text,
    input,
    textarea {
        border-radius: 0;
        background-color: $lighterblue;
        border: 0;
        height: 56px;
        border-bottom: 2px solid #c8dff2;
        letter-spacing: 0.3px;
        font-weight: $font-weight-bold;
        margin: 26px 0;
        font-size: 18px;
        padding: 16px;
        color: $primary;

        &:focus {
            outline: 0;
            border-bottom: 2px solid $secondary;
        }
    }

    .comment-form-cookies-consent {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;

        #wp-comment-cookies-consent {
            height: auto;
            left: 0;
            display: inline-block;
            width: auto;
        }

        label {
            left: 0;
            position: relative;
            top: 0;
            margin-bottom: 0;
        }
    }

    &.comment-form textarea {
        min-height: 192px;
    }

    .comment-form-cookies-consent {
        position: relative;
        z-index: 1;
        display: block;
        min-height: 1.5rem;
        padding-left: 1.5rem;
    }

    #wp-comment-cookies-consent {
        position: absolute;
        left: 0;
        z-index: -1;
        width: 1rem;
        height: 1.25rem;
        opacity: 0;
        margin-top: 14px;

        + label {
            position: relative;
            margin-bottom: 0;
            vertical-align: top;
            padding-left: 12px;
            font-weight: $font-weight-base;

            &:before {
                position: absolute;
                top: 0.25rem;
                left: -1.5rem;
                display: block;
                width: 1rem;
                height: 1rem;
                pointer-events: none;
                content: "";
                background-color: #fff;
                border: 2px solid $primary;
                height: 24px;
                width: 24px;
                border-radius: 3px;
            }

            &:after {
                position: absolute;
                top: 0.25rem;
                left: -1.5rem;
                display: block;
                width: 1rem;
                height: 1rem;
                content: "";
                background: no-repeat 50%/50% 50%;
            }
        }

        &:checked~label::before {
            // color: #fff;
            border-color: $primary;
            // background-color: $primary;
            background: $white icon(icon-checkmark, $primary) center center no-repeat;
        }
    }
}
// WP Forms
// -----------------------------------------------------------------------------

.wpforms-container-full .wpforms-form .wpforms-field-label.wpforms-label-hide {
    display: none;
}

.wpforms-confirmation-container-full,
.wpforms-confirmation-container-full.wpforms-confirmation-scroll {
    border: none;
    background-color: #0196D8;
    padding: 40px;
    color: white;
    font-weight: 900;
    font-size: 18px;
    line-height: 24px;

    p {
        font-weight: 900;
        font-size: 18px;
        line-height: 24px;
    }
}

.wpforms-container-full .wpforms-form {
    .wpforms-field-container {
        @include make-row;

        ul {
            width: 100%;

            li {
                &:before {
                    content: none;
                }
            }
        }
        // [class*='wpforms'].input-group,
        [class*='wpforms-field'] {
            margin: 0;
            position: relative;
            padding: 0 12px;
            width: 100%;

            input,
            textarea {
                border-radius: 0;
                background-color: $lighterblue;
                border: 0;
                min-height: 56px;
                border-bottom: 2px solid #c8dff2;
                letter-spacing: 0.3px;
                font-weight: $font-weight-bold;
                margin: 26px 0;
                font-size: 18px;
                padding: 12px;
                max-width: none;
                color: $primary;
                letter-spacing: 0.3px;
                @include media-breakpoint-up(lg) {
                    margin: 32px 0;
                    padding: 16px;
                    min-height: 64px;
                }

                &:focus {
                    outline: 0;
                    border-bottom: 2px solid $secondary;
                }
            }

            select {
                border-radius: 0;
                background-color: $lighterblue;
                border: 0;
                min-height: 56px;
                border-bottom: 2px solid #c8dff2;
                letter-spacing: 0.3px;
                font-weight: $font-weight-bold;
                margin: 26px 0;
                font-size: 18px;
                padding: 0 12px;
                max-width: none;
                color: $primary;
                @include media-breakpoint-up(lg) {
                    margin: 32px 0;
                    min-height: 64px;
                    padding: 0 16px;
                }

                &:focus {
                    outline: 0;
                    border-bottom: 2px solid $secondary;
                }
            }

            label {
                position: absolute;
                left: calc(12px + 16px);
                left: 12px;
                transition: all 0.2s ease-in-out;
                color: $dark;
                z-index: 10;
                top: 43px;
                @include media-breakpoint-up(lg) {
                    top: 54px;
                    left: 16px;
                }
                font-size: 18px;

                .wpforms-required-label {
                    color: $dark;
                    line-height: 1.3;
                }

                &.wpforms-field-label.checklist-heading {
                    position: relative;
                    font-weight: $font-weight-bold;
                    position: relative;
                    top: 0;
                    left: 0;
                    padding: 0;
                    margin-bottom: 24px;
                    font-size: 20px;
                    margin-top: 32px;
                }
            }

            .wpforms-field-description {
                margin-top: -22px;
                margin-bottom: 32px;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.3px;
                color: $dark;
                display: flex;
                align-items: center;
                padding-left: 68px;

                &:before {
                    content: 'i';
                    height: 20px;
                    width: 20px;
                    border: 2px solid $dark;
                    color: $dark;
                    line-height: 24px;
                    font-weight: 900;
                    border-radius: 50%;
                    font-size: 13px;
                    margin-right: 10px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    padding-left: 1px;
                    position: absolute;
                    left: 36px;
                }
            }

            &.focus label,
            &.has-input label {
                top: -4px;
                line-height: 24px;
                font-size: 16px;
                @include media-breakpoint-up(lg) {
                    top: -8px;
                }
            }

            textarea {
                min-height: 192px;
            }
        }

        .wpforms-one-half {
            @include make-col(12);
            @include media-breakpoint-up(lg) {
                @include make-col(6);
            }
            margin: 0;
            position: relative;
        }

        .wpforms-one-third {
            @include make-col(12);
            @include media-breakpoint-up(lg) {
                @include make-col(4);
            }
        }

        .wpforms-field-checkbox,
        .wpforms-field-radio {
            margin-bottom: 28px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 24px !important;
            }

            li {
                line-height: 24px;
                margin-bottom: 12px !important;
                @include media-breakpoint-up(lg) {
                    margin-bottom: 16px !important;
                }
            }
        }

        input[type='checkbox'],
        input[type='radio'] {
            position: absolute;
            left: 0;
            z-index: -1;
            width: 1rem;
            height: 1.25rem;
            opacity: 0;
            margin: 0;

            &:checked ~ label::before {
                // border-color: #005a8e;
                background: icon(icon-checkmark, $primary) center center no-repeat;
            }

            + label {
                position: relative !important;
                margin-bottom: 0;
                vertical-align: top;
                padding-left: 12px;
                padding-left: 36px;
                margin-bottom: 24px;
                top: 0;
                left: 0;
                min-height: 24px;
                cursor: pointer;
                display: inline-block;
                line-height: 24px;

                &:before {
                    position: absolute;
                    // top: 0.25rem;
                    top: 0;
                    // left: -1.5rem;
                    left: 0;
                    display: block;
                    width: 1rem;
                    height: 1rem;
                    pointer-events: none;
                    content: "";
                    background-color: #fff;
                    border: 2px solid #005a8e;
                    height: 24px;
                    width: 24px;
                    border-radius: 3px;
                }
            }
        }

        input[type='radio'] {
            + label {
                &:before {
                    border-radius: 50%;
                    // left: -16px;
                }
            }

            &:checked ~ label:before {
                background-image: none;
            }

            &:checked ~ label:after {
                // border-color: #005a8e;
                position: absolute;
                content: "";
                background: $primary;
                border-radius: 50%;
                width: 10px;
                height: 10px;
                left: 7px;
                top: 7px;
            }
        }
    }

    .wpforms-submit-container {
        button[type='submit'] {
            @extend .btn-primary;
            @extend .btn;
            border-width: 2px !important;
            // color: #fff;
            // background-color: #004268;
            // border-color: #003a5b;
        }
    }

    .wpforms-field.wpforms-field-select {
        > label {
            position: relative;
            top: 0;
            left: 0;
            font-size: 16px;
            line-height: 24px;
            display: inline;
        }

        &:before {
            content: "";
            position: absolute;
            right: 21px;
            top: 50%;
            display: block;
            height: 24px;
            width: 24px;
            transform: translate(0, -50%);
            background: icon(icon-chevron, $primary) center center no-repeat;
            z-index: 10;
        }

        select {
            margin-top: 4px;
            @include media-breakpoint-up(lg) {
                margin-top: 8px;
            }
            cursor: pointer;
            appearance: none;
        }
    }
}
// Error Handling
// -----------------------------------------------------------------------------

.wpforms-container-full .wpforms-form {
    .wpforms-field-container {
        .wpforms-field input.wpforms-error {
            border: 0;
            border-bottom: 2px solid #c8dff2;
        }

        .wpforms-has-error {
            &:after {
                content: '!';
                position: absolute;
                right: calc(12px + 16px);
                height: 20px;
                width: 20px;
                background-color: $red;
                top: 43px;
                color: white;
                text-align: center;
                line-height: 20px;
                font-weight: 900;
                border-radius: 50%;
                font-size: 14px;
                z-index: 10;
            }
        }

        label.wpforms-error {
            display: block;
            position: relative;
            color: red;
            top: -16px !important;
            font-weight: 400;
            letter-spacing: 0.3px;
            font-size: 16px;
            line-height: 24px;
        }
    }
}
// }
// @TODO: @BRUCE Temporary fix

.theme-formula {
    .wpforms-container-full .wpforms-form {
        .wpforms-field-container {
            .wpforms-field-checkbox,
            .wpforms-field-radio {
                margin-bottom: 0;
                @include media-breakpoint-up(lg) {
                    margin-bottom: 0 !important;
                }
            }

            input[type='checkbox'],
            input[type='radio'] {
                + label {
                    margin-bottom: 0;
                }
            }
        }
    }
}
// @TODO @Bruce: Das versteckt das * Feld, nachdem das Formular abgeschickt wurde

.form-control {
    background-clip: border-box;
}

.wpforms-confirmation-container-full + p.required-fields-notice {
    display: none;
}

.comment-form-accept {
    // padding-left: 1.5rem;

    padding-left: 8px;
    &.focus label {
        font-size: 18px !important;
        line-height: 27px !important;
        top: 0 !important;
        font-weight: $font-weight-base;
    }
    &:focus {

    }
    label {
        top: 0;
        position: relative;
        margin-bottom: 0;
        vertical-align: top;
        padding-left: 12px;
        font-weight: $font-weight-base;
        &:before {
            position: absolute;
            top: 0;
            // left: 0;
            display: block;
            width: 1rem;
            height: 1rem;
            pointer-events: none;
            content: "";
            background-color: #fff;
            border: 2px solid #005a8e;
            height: 24px;
            width: 24px;
            border-radius: 3px;
            left: -1.5rem;
            top: 0.25rem;
            box-shadow: none !important;
        }
    }

    input {
        position: absolute;
        left: 0;
        z-index: -1;
        width: 1rem;
        height: 1.25rem;
        opacity: 0;
        margin: 0;
        &:checked ~ label.custom-control-label:before {
            background: $white icon(icon-checkmark, $primary) center center no-repeat;
        }
        &:active ~ .custom-control-label::before {
            border-color: $primary !important;
            background-color: $white !important;
            box-shadow: none !important;
        }
    }
    a {
        font-weight: $font-weight-base;
        font-size: 18px;
        box-shadow: 0 1px 0 0 $primary;
        &:hover {
            box-shadow: 0 1px 0 0 $primary;
        }
    }
}
// Newsletter Anmeldung Checkbox
// -----------------------------------------------------------------------------

.cr_ipe_item.ui-sortable.custom-control.custom-checkbox {
    padding-left: 0;
}

.cr_ipe_checkbox.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
    margin: 0;

    + label {
        position: relative !important;
        margin-bottom: 0;
        vertical-align: top;
        padding-left: 12px;
        padding-left: 36px;
        margin-bottom: 24px;
        top: 0;
        left: 0;
        min-height: 24px;
        cursor: pointer;
        display: inline-block;
        line-height: 24px;
        font-weight: 400;

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 1rem;
            height: 1rem;
            pointer-events: none;
            content: "";
            background-color: #fff;
            border: 2px solid #005a8e;
            height: 24px;
            width: 24px;
            border-radius: 3px;
        }
    }

    &:checked ~ label::before {
        // border-color: #005a8e;
        background: icon(icon-checkmark, $primary) center center no-repeat;
    }
}
