// Section Card Wall
// -------------------------------------------------------------------------------------------------------------------

.gp-section-card-logo-wall {
    padding-top: 40px;
    padding-bottom: 40px;
    @include media-breakpoint-up(md) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @include media-breakpoint-up(xl) {
        padding-top: 160px;
        padding-bottom: 80px;
    }

    .btn-primary {
        margin: 20px auto 0;
    }

    .row-cards {
        @include media-breakpoint-down(sm) {
            padding: 0 12px;
            [class^='col'] {
                padding-left: 12px;
                padding-right: 12px;
            }
        }
        @include media-breakpoint-up(lg) {
            justify-content: center;
        }
    }
}
// Section Card Wall + Slider
// -------------------------------------------------------------------------------------------------------------------

.gp-section-card-logo-slider,
.gp-section-card-logo-wall {
    .card-logo {
        padding: 16px;
        background: white;
        display: flex;
        margin-bottom: 20px;
        @include media-breakpoint-up(xl) {
            padding: 32px;
        }
    }
    .col-12 {
        display: flex;
    }

    h2 {
        margin: 0 auto 48px;
        text-align: center;
    }

    img,
    picture {
        width: 100%;
        height: auto;
    }
}
.gp-section-card-logo-slider {
    @include media-breakpoint-down(sm) {
        .row-cards {
            padding: 0 4px;
        }
    }
    @include media-breakpoint-only(md) {
        .row-cards {
            margin: 0;
        }
    }
    @include media-breakpoint-down(md) {
        .col-6 {
            padding-left: 8px;
            padding-right: 8px;
        }
    }
}
// Footer
// -------------------------------------------------------------------------------------------------------------------

.gp-section-card-logo-slider {
    position: relative;
    overflow-x: hidden;
    padding-bottom: 40px;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
        padding-bottom: 80px;
    }
    @include media-breakpoint-up(xl) {
        background-size: cover;
    }

    > picture {
        position: absolute;
        height: 100%;
        width: 100%;

        img {
            object-fit: cover;
            min-width: 100%;
            min-height: 100%;
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
        }
    }

    &:focus,
    .card:focus {
        outline: none;
    }

    .container:first-of-type {
        padding-top: 40px;
        @include media-breakpoint-up(lg) {
            padding-top: 120px;
        }
        @media (min-width: 1440px) {
            padding-top: 80px;
        }
    }

    .card-logo-slider {
        margin: 0 -12px 20px;
        @include media-breakpoint-down(md) {
            padding: 0 15px;
            margin: 0 0 20px;
        }
        @include media-breakpoint-down(sm) {
            padding: 0 12px;
            margin: 0 -8px;
        }
    }

    .slick-list {
        overflow: visible;
    }
    // Footer
    // -------------------------------------------------------------------------------------------------------------------
    .footer {
        display: flex;
        position: relative;
        @include media-breakpoint-down(sm) {
            flex-direction: column-reverse;

            .btn {
                width: fit-content;
                margin: 32px auto 0;
                // position: relative;
                // left: 0;
                // transform: none;
            }

            .slick-dots {
                position: relative;
            }
        }
        @include media-breakpoint-up(md) {
            .btn {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    // Navigation
    // -------------------------------------------------------------------------------------------------------------------
    .slick-next,
    .slick-prev {
        background-color: $primary;

        &:before {
            width: 24px;
            height: 24px;
            background: icon(icon-arrow-right, $white) no-repeat;
        }
    }

    .slider-nav {
        margin-left: auto;

        button {
            width: 48px;
            height: 48px;
            margin: 0 16px;

            &:last-of-type {
                margin-right: 0;
            }
        }

        .pagingInfo {
            color: $white;
        }
    }
    // Dots
    // -------------------------------------------------------------------------------------------------------------------
    .slick-dots {
        left: -14px;
        @include media-breakpoint-up(sm) {
            left: calc((100vw - 540px) / -2);
            left: -14px;
            left: calc(((100vw - 540px) / -2) - 2px);
        }
        @include media-breakpoint-up(md) {
            display: none !important;
            left: calc(((100vw - 720px) / -2) - 2px);
        }
        @include media-breakpoint-up(lg) {
            left: calc(((100vw - 960px) / -2) - 0px);
        }

        button {
            background-color: rgba($primary, .5);
        }
    }

    &.light {
        // h2 {
        //     color: $white;
        // }
        // .slick-next,
        // .slick-prev {
        //     background-color: $white;
        //
        //     &:after,
        //     &:before {
        //         background: icon(icon-arrow-right, $primary) no-repeat;
        //     }
        // }
        .footer {
            .btn {
                background-color: #fff;
                border-color: #fff;
                color: $primary;

                &:hover {
                    color: #212529;
                    background-color: #ececec;
                    border-color: #e6e6e6;
                }
            }
        }

        .slick-dots {
            button {
                background-color: rgba($white, .5);
            }

            li.slick-active button {
                background-color: $white;
            }
        }
    }
}
