section[class^='gp-stage-'] {
    overflow: hidden;
    position: relative;
    @include media-breakpoint-down(sm) {
        background-color: $white;
    }
    .container-fluid {
        @include media-breakpoint-down(xs) {
            padding: 0;
        }
    }

    .media,
    picture {
        @include media-breakpoint-down(xs) {
            // min-height: 100vw;
            min-height: calc((100vw / 3) * 2);
            display: block;
            position: relative;

            img,
            video {
                height: 100%;
                width: auto;
                left: 50%;
                transform: translateX(-50%);
                position: absolute;
            }

            video {
                height: 100vw;
                width: auto;
            }
        }
        @include media-breakpoint-up(sm) {
            video {
                width: 100vw;
                height: auto;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                left: 50%;
            }
        }
    }
    .media {
        @include media-breakpoint-between(sm, md) {
            height: calc((100vw / 3) * 2);
            position: relative;
            video {
                width: auto;
                height: 100%;
            }
        }
    }

    .h1 {
        @include media-breakpoint-down(md) {
            font-size: calc(1.125rem * (40 / 18));
            line-height: 1.2;
        }
        @include media-breakpoint-only(md) {
            width: calc(((100% / 12) * 10) - 24px);
            margin-left: auto;
            margin-right: auto;
        }
        @include media-breakpoint-only(lg) {
            width: calc(((100% / 12) * 10) - 36px);
            margin-left: auto;
            margin-right: auto;
        }
    }

    img {
        width: 100%;
        height: auto;
        @include media-breakpoint-up(md) {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
        }
    }
    .card-body {
        :last-child {
            margin-bottom: 0;
        }
    }
}
