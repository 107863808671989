.gp-teaser-image {
    padding: 64px 0 0;
    @include media-breakpoint-up(lg) {
        padding: 104px 0 0;
        margin-top: 16px;
    }
    @media (min-width: 1440px) {
        margin-top: 56px;
    }
    @media (min-width: 1680px) {
        padding: 160px 0 0;
        margin-top: 0;
    }

    .container, .container-fluid {
        position: relative;
        overflow: hidden;
        @include media-breakpoint-between(sm, md) {
            > .row {
                margin: 0;
            }
        }
        @include media-breakpoint-up(lg) {
            padding-top: 48px;
            padding-bottom: 48px;
        }
        @include media-breakpoint-up(xl) {
            padding-top: 120px;
            padding-bottom: 120px;
            height: 708px;
        }
    }

    picture {
        @include media-breakpoint-up(lg) {
            position: absolute;
            height: 100%;
            width: calc(100% - 24px);
            overflow: hidden;
            top: 0;
            left: 12px;
        }

        + div {
            @include media-breakpoint-down(md) {
                background-color: white;
                padding: 0;
            }
        }
    }

    .card {
        background-color: transparent;
    }

    .card-body {
        padding: 24px 24px 32px;
        @include media-breakpoint-up(lg) {
            padding: 0;
        }
    }

    img {
        width: 100%;
        height: auto;
        @include media-breakpoint-up(lg) {
            position: absolute;
            height: 100%;
            top: 0;
            right: 0;
            height: 100%;
            width: auto;
            min-height: 100%
        }
    }
}

.gp-teaser-image.v01 {
    .container {
        @include media-breakpoint-up(xl) {
            height: 617px;
        }
    }

    h5 {
        margin-bottom: 20px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 32px;
        }
    }

    picture {
        width: 100%;
        @include media-breakpoint-up(lg) {
            width: calc(100% - 24px);
        }
    }
}

.gp-teaser-image.v1-2, .gp-teaser-image.v1-1 {
    > .container {
        padding-top: 0;
        padding-bottom: 0;
        .row {
            height: 100%;
            @include media-breakpoint-down(md) {
                > div[class^='col-'] {
                    padding: 0;
                    .row {
                        margin: 0;
                    }
                }
            }
        }
    }
    .card {
        @include media-breakpoint-up(sm) {
            padding: 56px;
        }
    }
}
