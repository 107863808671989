.theme-formula {
  nav.navbar {
    .navbar-brand {
      img {
        height: 48px;
        width: auto;
        transition: $transition-base;
      }
    }
  }

  nav.navbar:not(.mobileNav) {
    .navbar-brand {
      img {
        height: 48px;
        width: auto;
        transition: $transition-base;
        @include media-breakpoint-up(xl) {
          width: 140px;
          height: auto;
          transition: $transition-base;
        }
      }
    }

    &.fixed .navbar-brand img {
      height: 48px;
      width: auto;
      transition: $transition-base;
    }
  }

  footer#colophon {
    #footer-widget {
      > div {
        margin-top: 48px;

        &:last-child:after {
          content: none;
        }
      }
    }

    > .container:first-of-type {
      padding-bottom: 0;
    }
  }

  .lp-section-card-product {
    // .card-back .card-body, .card-back .card-footer, .card-back .card-header {
    //     background-color: $lightblue;
    // }
    .card-back li {
      background: none;

      &:before {
        top: 3px;
        background: $primary icon(icon-checkmark, $lightblue) center center no-repeat;
      }
    }

    .CC_BuyBtn1 {
      margin: 0 !important;
      button {
        @extend .btn-outline-primary;
        @extend .btn-sm;
        @extend .btn;
        height: auto;
        background-color: transparent !important;
        width: auto;
        &:hover {
          background-color: $primary !important;
          border-color: $primary !important;
        }
      }
    }
  }
}
