footer {
    .sm-1 {
        margin-bottom: 20px;
        font-weight: $font-weight-bold;
    }

    .img-container {
        display: flex;
        flex-wrap: wrap;

        img {
            margin-right: 16px;
            margin-bottom: 16px;
        }
    }

    &#colophon span.site-title,
    &#colophon a.site-title {
        font-size: 48px;
        font-weight: 700;
    }

    button.sm-1 {
        background: none;
        border: none;
        padding: 0;
        text-align: left;
        display: inherit;
        outline: 0;
    }
    @include media-breakpoint-up(lg) {
        [data-toggle] {
            cursor: default;
            pointer-events: none;
        }

        .collapse:not(.show) {
            display: block;
        }
    }

    .copyright {
        background-color: $link-hover-color;

        p {
            margin: 32px 0;
        }
    }
    // TODO: Temporary fix
    //&#colophon {
    //    padding-bottom: 0;
    //}

    .footer-brand {
        margin-top: 16px;
        margin-bottom: 16px;
        margin-bottom: 0;

        @include media-breakpoint-down(md) {
            padding-bottom: 40px !important;
        }
        @include media-breakpoint-up(lg) {
            margin-top: 32px;
        }
    }

    .footer-nav {
        padding-bottom: 80px;
    }
}
// TODO: Temporary fix
body:not(.theme-formula) footer {
    &#colophon {
        padding-bottom: 0;
    }
}

@include media-breakpoint-down(md) {
    footer#colophon {
        #footer-widget {
            .sm-1 {
                width: 100%;
                padding-top: 14px;
            }

            > div {
                margin-top: 14px;
                overflow-x: hidden;
                &:first-child {
                    margin-top: 0;
                }

                ~ div {
                    .sm-1 {
                        margin-bottom: 0;
                        font-size: 18px;

                        &:before {
                            width: calc(100% + 48px);
                            // width: 200%;
                            // left: -50%;
                            height: 2px;
                            left: -24px;
                            background-color: $lightblue;
                            @include media-breakpoint-up(md) {
                                width: calc(100% + 72px);
                                left: -36px;
                            }
                        }

                        &:after {
                            background: icon(icon-chevron, $white) no-repeat;
                            height: 24px;
                            width: 24px;
                            right: 0;
                            content: '';
                            position: absolute;
                            transform: rotate(0deg);
                            // transition: $transition-base;
                        }

                        &.open {
                            &:after {
                                transform: rotate(-180deg);
                                // transition: $transition-base;
                            }
                        }
                    }
                }

                &:last-child {
                    // border-bottom: 2px solid $white;
                    padding-bottom: 12px;

                    &:after {
                        // width: calc(100% + 48px);
                        width: calc(100% + 24px);
                        left: -12px;
                        height: 2px;
                        // left: -24px;
                        position: absolute;
                        content: '';
                        bottom: 0;
                        background-color: $lightblue;
                        @include media-breakpoint-up(md) {
                            width: calc(100% + 48px);
                            left: -24px;
                        }
                    }
                }
            }

            .zoom-social-icons-widget {
                .collapse,
                .collapsing {
                    padding-top: 12px;
                }
            }

            .collapse {
                opacity: 0;
                transition: $transition-base;

                li {
                    padding: 0;

                    a {
                        padding: 10px 0;
                    }
                }
            }

            .collapse,
            .collapsing {
                padding: 22px 0 18px 0;
                height: auto;
                opacity: 1;
                transition: $transition-base;
            }
        }

        > .container:first-of-type {
            padding-bottom: 64px;
        }
    }
}
