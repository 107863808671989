.accordion {
    border-bottom: 2px solid $lightblue;
    .collapse, .collapsing, .show {
        padding: 0;
    }
    .card-header {
        padding: 0;
        background-color: transparent;
        button {
            border: 2px solid $lightblue;
            border-width: 2px 0 0 0;
            background-color: transparent;
            width: 100%;
            padding: 20px 30px 20px 0;
            color: $primary;
            font-weight: $font-weight-bold;
            position: relative;
            text-align: left;
            &:focus, &:active {
                outline: 0px auto -webkit-focus-ring-color;
            }
            &:before, &:after {
                content: '';
                position: absolute;
                width: 2px;
                height: 20px;
                background-color: $primary;
                top: 50%;
                right: 10px;
            }
            &:after {
                transform: translateY(-50%) rotate(0deg);
            }
            &:before {
                transform: translateY(-50%) rotate(90deg);
            }
            &[aria-expanded='true'] {
                // &:before, &:after {
                //     // transition: .3s ease-out;
                // }
                &:after {
                    transform: translateY(-50%) rotate(90deg);

                }
                &:before {
                    transform: translateY(-50%) rotate(-90deg);
                }
            }

        }
    }
    > .card > .collapse > .card-body, > .card > .collapsing > .card-body {
        // padding-left: 0;
        // padding-right: 0;
        padding: 16px 0 8px;
        .row {
            justify-content: center;
            @include media-breakpoint-down(sm) {
                margin: 0;
                > div {
                    padding: 0;
                }
            }

        }
    }
}
