.gp-section-card-product {
    position: relative;
    // overflow-x: hidden;
    overflow: hidden;
    padding-bottom: 40px;
    @include media-breakpoint-up(lg) {
        padding-bottom: 80px;
    }
    @include media-breakpoint-up(xl) {
        background-size: cover;
    }

    .results {
        text-align: center;
        color: $primary;
        margin-bottom: 40px;
    }

    > picture {
        position: absolute;
        height: 100%;
        width: 100%;

        img {
            object-fit: cover;
            min-width: 100%;
            min-height: 100%;
        }
    }

    &:focus,
    .card:focus {
        outline: none;
    }
    ul.products {
        margin-bottom: 0;
    }

    > .container:first-of-type {
        padding-top: 40px;
        @include media-breakpoint-up(lg) {
            padding-top: 80px;
        }
        // @media (min-width: 1440px) {
        //     padding-top: 80px;
        // }
    }
    // Content
    // -------------------------------------------------------------------------------------------------------------------
    // .container-fluid {
    //     position: relative;
    //     z-index: 1;
    //     padding-top: 32px;
    //     overflow: hidden;
    //     @include media-breakpoint-up(md) {
    //         padding-top: 48px;
    //     }
    //
    //     + .container > .row:last-of-type {
    //         text-align: center;
    //         justify-content: center;
    //     }
        // Slick Slider
        // ---------------------------------------------------------------
        // @include media-breakpoint-only(md) {
        //     padding-left: calc(((100vw - 720px) / 2) + 12px);
        //     padding-right: 0;
        //
        //     > .row {
        //         margin: 0 -10px;
        //     }
        // }
        // @include media-breakpoint-only(lg) {
        //     margin: 0;
        //     padding-left: calc(((100vw - 960px) / 2) + 12px);
        // }
        // @include media-breakpoint-up(xl) {
        //     // margin: 0 auto;
        //     // width: 100%;
        //     // max-width: 1464px;
        //     margin: 0;
        //     // padding-left: calc(((100vw - 1200px) / 2) + 12px);
        //     .slick-track {
        //         margin-left: 0;
        //     }
        // }
        // @media (min-width: 1464px) {
        //     padding-left: calc(((100vw - 1464px) / 2) + 4px);
        // }

        .card-product-slider {
            // padding-bottom: 52px;
            margin-bottom: 40px;
            margin-top: 40px;

            @include media-breakpoint-down(xs) {
                margin: 32px 22px 40px 2px;
            }
            //@include media-breakpoint-only(lg) {
            //    max-width: 720px;
            //}

            .slick-track {
                display: flex;
                align-items: stretch;
                // min-width: 100%;
            }
            &.lessThan4 {
                @include media-breakpoint-up(xl) {
                    margin-bottom: 0;
                }
                .slick-track {
                    @include media-breakpoint-up(xl) {
                        min-width: 100%;
                        justify-content: center;
                    }
                    @include media-breakpoint-up(sm) {
                        .slick-slide {
                            max-width: calc((1464px / 4));
                            min-width: calc((1464px / 4));
                        }
                    }
                    // @include media-breakpoint-down(md) {
                    //     .slick-slide {
                    //         flex: auto;
                    //         // max-width: none;
                    //     }
                    // }
                }
            }

            .slick-list {
                overflow: visible;
                width: 100%;
                display: flex;
            }

            // slide item
            // -------------------------------------------------------------------------------------------------------------------
            [class^='col'] {
                width: 300px;
                padding: 0 12px;
                @include media-breakpoint-down(lg) {
                    flex: auto;
                    max-width: none;
                }
                @include media-breakpoint-down(sm) {
                    padding-left: 10px;
                    padding-right: 10px;
                }
                @include media-breakpoint-up(lg) {
                    max-width: calc((1464px / 4)) !important;
                    min-width: calc((1464px / 4)) !important;
                    width: calc((1464px / 4)) !important;
                }
            }
        }
    // }
    .row:not(.slick-slider) {
        justify-content: center;
        // .card {
        //     margin-bottom: 24px;
        //     @include media-breakpoint-down(xs) {
        //         margin-bottom: 16px;
        //     }
        // }
    }
    .card {
        //background-color: transparent;
        color: $primary;
        display: flex;
        flex-direction: column;
        min-height: 100%;
        img {
            width: auto;
            object-fit: contain;
            max-height: 100%;
            max-width: 100%;
            margin: 0 auto;
            //min-width: 100%;
            height: auto;
        }

        .card-header {
            background-color: $white;
            padding: 56px 40px 0;
            margin-top: 0;
            position: relative;

            picture {
                position: relative;
                width: 100%;
                display: flex;
            }
        }

        .card-body {
            display: flex;
            align-items: center;
            padding-bottom: 40px;
            padding-top: 24px;
            flex-direction: column;
            background-color: $white;
            min-height: auto;
            @include media-breakpoint-up(lg) {
                // padding-bottom: 24px;
                padding-bottom: 40px;
                padding-top: 32px;
            }

            h6 {
                margin: 8px 0 0;
                // line-height: 32px;
                text-align: center;
                max-width: 100%;

                &:after {
                    content: none;
                }
            }

            p {
                margin-bottom: 0;
                color: $gray-700;
                line-height: 24px;
            }
            .sub-category {
                font-size: 16px;
                font-weight: $font-weight-bold;
                text-align: center;
            }
        }

        // &.slick-slide {
            // @include make-col-ready();

            @include media-breakpoint-up(sm) {
                // @include make-col(6);
                picture {
                    // max-height: calc(((100vw / 2) - (2 * 24px)) - 80px);
                    max-height: calc(((320px / 1) - (1 * 20px)) - 80px);
                    min-height: calc(((320px / 1) - (1 * 20px)) - 80px);
                    //height: calc(((320px / 1) - (1 * 20px)) - 80px);
                }
            }
            @include media-breakpoint-up(md) {
                // @include make-col(4);
                picture {
                    // max-height: calc(((960px / 3) - (1 * 24px)) - 80px);
                    max-height: calc(((640px / 2) - (1 * 20px)) - 80px);
                    min-height: calc(((640px / 2) - (1 * 20px)) - 80px);
                    //height: calc(((640px / 2) - (1 * 20px)) - 80px);
                }
            }
            @include media-breakpoint-up(lg) {
                picture {
                    max-height: calc(((960px / 3) - (2 * 22px)) - 80px);
                    min-height: calc(((960px / 3) - (2 * 22px)) - 80px);
                    //height: calc(((960px / 3) - (2 * 22px)) - 80px);
                }
            }
            @include media-breakpoint-up(xl) {
                // @include make-col(3);
                picture {
                    max-height: calc(((100vw / 4) - (1 * 24px)) - 80px);
                    min-height: calc(((100vw / 4) - (1 * 24px)) - 80px);
                    //height: calc(((100vw / 4) - (1 * 24px)) - 80px);
                }
            }
            @media (min-width: 1464px) {
                picture {
                    max-height: calc(((1440px / 4) - (1 * 24px)) - 80px);
                    min-height: calc(((1440px / 4) - (1 * 24px)) - 80px);
                    //height: calc(((1440px / 4) - (1 * 24px)) - 80px);
                    // (((100vw - 1464px) / 2) + 12px)
                }
            }
        // }
    }
    .slick-slide {
        picture {
            max-height: calc(((320px / 1) - (1 * 20px)) - 80px);
            min-height: calc(((320px / 1) - (1 * 20px)) - 80px);
            height: calc(((320px / 1) - (1 * 20px)) - 80px);
        }
    }

    // .product .card:not(.slick-slide)  {
    // .container .card,
    .product:not(.slick-slide) .card.woocommerce-loop-product__link,
    [class^='col']:not(.slick-slide) .card {

        // margin-bottom: 24px;
        @include media-breakpoint-down(xs) {
            flex-direction: row;

            .card-header {
                padding: 26px 16px 26px 20px;

                picture {
                    height: 96px;
                    width: 96px;
                    display: block;
                }
            }

            .card-body {
                padding: 26px 20px 20px 0;

                * {
                    text-align: left;
                    width: 100%;
                }
            }
        }
    }

    .slick-slide .card {
         @include media-breakpoint-down(xs) {
             flex-direction: column;
         }
    }

    div.product {
        margin-bottom: 16px;

        &.slick-slide {
            margin-bottom: 0;
            height: auto;
            .woocommerce-loop-product__link {
                flex-direction: column;
            }
            picture {
                max-height: calc(((320px / 1) - (1 * 20px)) - 80px);
                min-height: calc(((320px / 1) - (1 * 20px)) - 80px);
            }
            @include media-breakpoint-down(xs) {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        .card {
            @include media-breakpoint-up(sm) {
                height: 100%;
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-down(xs) {
            padding-left: 24px;
            padding-right: 24px;
        }
        @include make-col-ready();
        @include media-breakpoint-up(sm) {
            margin-bottom: 24px;
            // @include make-col(4);
            &:not(.slick-slide) {
                @include make-col(6);
                picture {
                    max-height: calc(((540px / 2) - (1 * 24px)) - 80px);
                    min-height: calc(((540px / 2) - (1 * 24px)) - 80px);
                }
            }
            picture {
                // max-height: calc(((100vw / 2) - (2 * 24px)) - 80px);
                max-height: calc(((540px / 2) - (1 * 24px)) - 80px);
                min-height: calc(((540px / 2) - (1 * 24px)) - 80px);
            }
        }
        @include media-breakpoint-up(md) {
            &:not(.slick-slide) {
                // @include make-col(4);
                picture {
                    max-height: calc(((720px / 3) - (1 * 24px)) - 80px);
                    min-height: calc(((720px / 3) - (1 * 24px)) - 80px);
                }
            }
            // @include make-col(4);

            picture {
                max-height: calc(((640px / 2) - (1 * 24px)) - 80px);
                min-height: calc(((640px / 2) - (1 * 24px)) - 80px);
            }
        }
        @include media-breakpoint-up(lg) {
            // @include make-col(4);
            &:not(.slick-slide) {
                @include make-col(4);
                picture {
                    // max-height: calc(((960px / 3) - (1 * 24px)) - 80px);
                    // min-height: calc(((960px / 3) - (1 * 24px)) - 80px);
                    max-height: calc(((100vw / 4) - (1 * 24px)) - 80px);
                    min-height: calc(((100vw / 4) - (1 * 24px)) - 80px);
                }
            }
            picture {
                // max-height: calc(((960px / 3) - (1 * 24px)) - 80px);
                // min-height: calc(((960px / 3) - (1 * 24px)) - 80px);
                max-height: calc(((720px / 2) - (1 * 24px)) - 80px);
                min-height: calc(((720px / 2) - (1 * 24px)) - 80px);
            }
        }
        @include media-breakpoint-up(xl) {
            @include make-col(3);

            picture {
                max-height: calc(((100vw / 4) - (1 * 24px)) - 80px);
                min-height: calc(((100vw / 4) - (1 * 24px)) - 80px);
            }
            &:not(.slick-slide) {
                @include make-col(3);
                // picture {
                //     // max-height: calc(((960px / 3) - (1 * 24px)) - 80px);
                //     // min-height: calc(((960px / 3) - (1 * 24px)) - 80px);
                //     max-height: calc(((100vw / 4) - (1 * 24px)) - 80px);
                //     min-height: calc(((100vw / 4) - (1 * 24px)) - 80px);
                // }
            }
        }
        @media (min-width: 1464px) {
            &:not(.slick-slide) {
                // @include make-col(3);
                picture {
                    max-height: calc(((1464px / 4) - (1 * 24px)) - 80px);
                    min-height: calc(((1464px / 4) - (1 * 24px)) - 80px);
                }
            }
            picture {
                max-height: calc(((1440px / 4) - (1 * 24px)) - 80px);
                min-height: calc(((1440px / 4) - (1 * 24px)) - 80px);
            }
        }
    }

    // Dots
    // -------------------------------------------------------------------------------------------------------------------
    .slick-dots {
        width: 100%;
        left: auto;
        @include media-breakpoint-up(md) {
            display: none !important;
        }
    }
    // Footer
    // -------------------------------------------------------------------------------------------------------------------
    .btn-lg {
        background-color: #c3002d;
        border: #c3002d;

        &:active,
        &:focus,
        &:hover {
            background-color: darken(#c3002d, 5%);
        }
    }
    // Footer
    // -------------------------------------------------------------------------------------------------------------------
    .footer {
        display: flex;
        position: relative;

        &:not(:empty) {
            // min-height: 48px;
        }
        @include media-breakpoint-down(sm) {
            flex-direction: column-reverse;

            .btn.btn-light {
                width: fit-content;
                margin: 32px auto 0;
                position: relative;
                left: 0;
                transform: none;
            }

            .slick-dots {
                position: relative;
            }
        }

        .btn {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }

        .btn-light {
            + .slider-nav {
                margin: 0 0 0 auto;
                button:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }
    // Navigation
    // -------------------------------------------------------------------------------------------------------------------
    .slider-nav {
        // margin-left: auto;
        margin: 0 auto;

        .slick-next:before,
        .slick-prev:before {
            // content: url("media/icons/ic-arrow-24.svg");
            content: '';
            background: icon(icon-arrow-right, $primary) no-repeat;
            width: 24px;
            height: 24px;
            transition: $transition-base;
        }
        .slick-disabled {
            background-color: #a39f9e;
            transition: $transition-base;
            &:before {
                background: icon(icon-arrow-right, $white) no-repeat;
                content: '';
                // height: 24px;
                // width: 24px;
            }
        }

        button {
            width: 48px;
            height: 48px;
            margin: 0 16px;
            background-color: $white;
            transition: $transition-base;
            &:hover {
                background-color: $gray-100;
                transition: $transition-base;
            }

            // &:last-of-type {
            //     margin-right: 0;
            // }
        }

        .pagingInfo {
            color: $white;
        }
    }
}

.nav-breadcrumb + .gp-section-card-product {
    .container:first-of-type {
        padding-top: 40px;
        @include media-breakpoint-down(md) {
            padding-top: 24px;

            .results {
                // margin
                margin-bottom: 24px;
            }
        }
    }
}
// Badge
// -------------------------------------------------------------------------------------------------------------------

// .card {
    div[class^='badge-'] {
        margin: 8px auto 0 0;
        height: 28px;
        width: auto !important;
        border-radius: 14px;
        padding: 2px 16px;
        font-size: 14px;
        color: white;
        position: relative;
        @include media-breakpoint-up(sm) {
            height: 32px;
            font-size: 16px;
            border-radius: 26px;
        }
        .slick-slider & {
            position: absolute;
            right: auto;
            top: 16px;
            left: 16px;
            margin: 0;
        }
        @include media-breakpoint-up(sm) {
            margin: 0;
            position: absolute;
            right: auto;
            top: 16px;
            left: 16px;
            line-height: 24px;
            // padding: 0px 16px;
            .slick-slider & {
                left: 28px;
            }
        }
        .gp-stage-product & {
            position: absolute;
            left: 24px;
            @include media-breakpoint-up(sm) {
                height: 52px;
                border-radius: 26px;
                padding: 2px 24px;
                span {
                    font-size: 20px;
                    line-height: 46px;
                }
                &:before {
                    height: 48px;
                    border-radius: 24px;
                }
            }
        }
        span {
            font-size: 14px;
            font-weight: $font-weight-bold;
            line-height: 24px;
            letter-spacing: .3px;
            min-height: 100%;
            @include media-breakpoint-up(sm) {
                height: 32px;
                font-size: 16px;
                line-height: 28px;
            }
        }
        &:after,
        &:before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &:before {
            height: 24px;
            border-radius: 12px;
            width: calc(100% - 4px);
            border: 1px solid rgba($white, .6);
            @include media-breakpoint-up(sm) {
                height: 28px;
                border-radius: 14px;
                width: calc(100% - 6px);
            }
        }
    }
    .badge-green {
        background-color: $teal;
    }
    .badge-rose {
        background-color: #F38091;
    }
    .badge-pink {
        background-color: $pink;
    }
    .badge-blue {
        background-color: $secondary;
    }
    // .badge-info,
    // .badge-new {
    //     margin: 8px auto 0 0;
    //     @include media-breakpoint-up(sm) {
    //         margin: 0;
    //         // height: 104px;
    //         // width: 104px;
    //         height: 32px;
    //         width: auto;
    //         background-color: $secondary;
    //         // border-radius: 50%;
    //         border-radius: 16px;
    //         position: absolute;
    //         right: 32px;
    //         top: 32px;
    //         padding: 0 16px;
    //         font-size: 16px;
    //         color: white;
    //         .slick-slider & {
    //             right: 44px;
    //         }
    //     }
    // }

    // .badge-info {
    //     @include media-breakpoint-up(sm) {
    //         background-color: $lightblue;
    //         color: $primary;
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //
    //         &:before {
    //             border-color: $primary;
    //         }
    //
    //         &:after {
    //             content: '';
    //         }
    //     }
    // }
// }
