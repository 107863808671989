.card-product-info {
    [class^='col'] {
        margin-bottom: 24px;
    }
    .card .card-body {
        > a {
            margin-bottom: 12px;
            @include media-breakpoint-up(sm) {
                margin-bottom: 24px;
            }
            &:hover h6 {
                color: $link-hover-color;
            }
        }
    }

    // TODO @Bruce: An dieser Stelle so hinzugefügt, weil ich nicht weiß, ob die Card noch wo anders verwendet wird
    &#press_products .card .card-body {
        > a {
            margin-bottom: 12px; // "Gefühlter" Wert :)
        }
        .sub-information {
            text-align: center;
            margin-bottom: 12px; // "Gefühlter" Wert :)
            color: $gray-700; // "Gefühlter" Wert :)
        }
    }

    .card-download {
        display: flex;
        background-color: $white;
        position: relative;
        justify-content: space-between;
        // padding: 24px 24px 40px;
        width: 100%;
        padding: 12px 0;
        margin-top: 0;
        @include media-breakpoint-up(sm) {
            margin-top: auto;
            padding: 24px 0 16px;
        }
        @include media-breakpoint-only(sm) {
            padding-bottom: 24px;
        }
        &:before {
            content: '';
            // width: calc(100% - 48px);
            width: 100%;
            height: 2px;
            background-color: $lighterblue;
            position: absolute;
            top: 0;
            // left: 24px;
            left: 0;
        }
        a {
            position: relative;
            // width: 50%;
            padding-left: 26px;
            font-weight: $font-weight-bolder;
            &:before {
                content: '';
                background: icon(icon-download, $primary) center center no-repeat;
                height: 20px;
                width: 20px;
                position: absolute;
                left: 0;
            }
            &:hover {
                &:before {
                    background: icon(icon-download, $link-hover-color) center center no-repeat;
                }
            }
        }
        @include media-breakpoint-down(sm) {
            flex-direction: column;
            padding-bottom: 0;
            a {
                width: 100%;
                + a {
                    margin-top: 12px;
                }
            }
        }

    }
}
