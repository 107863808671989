.gp-section-card-author {
    [class^='container'] {
        padding-top: 64px;
        padding-bottom: 64px;
        @include media-breakpoint-up(md) {
            padding-top: 80px;
            padding-bottom: 80px;
        }
        @include media-breakpoint-up(md) {
            padding-top: 160px;
            padding-bottom: 160px;
        }
    }

    .container-md {
        @include media-breakpoint-down(sm) {
            padding: 0;
        }
    }
    .row {
        position: relative;
        > div:last-of-type {
            @include media-breakpoint-down(sm) {
                padding-right: 12px;
                padding-left: 12px;
            }
            @include media-breakpoint-down(xs) {
                padding-right: 0;
                padding-left: 0;
            }
        }
    }
    .col-media {
        position: absolute;
        z-index: 1;
        // padding-left: calc(((720px / 12) * 2) + 12px);
        @include media-breakpoint-only(sm) {
            picture {
                padding-left: 32px;

            }
        }
        @include media-breakpoint-only(md) {
            padding-left: calc(((720px / 12) * 2) + 12px);
        }
        @include media-breakpoint-up(lg) {
            // position: absolute;
        }
    }
    img {
        width: 100%;
        height: auto;
        // max-width: 156px;
        @include media-breakpoint-down(sm) {
            max-width: 156px;
        }
        @include media-breakpoint-only(sm) {
            max-width: 216px;
        }
    }
    .card {
        @include media-breakpoint-down(xs) {
            margin-top: 40px;
            padding: 160px 24px 24px;
        //     &:before {
        //         content: '';
        //         width: 100vw;
        //         height: 100%;
        //         position: absolute;
        //         background-color: $white;
        //         top: 0;
        //         left: calc((100% - 540px) / -2);
        //     }
        }
        margin-top: calc((720px / 2) + 0px);
        margin-top: 80px;
        padding: calc(100vw / 4) 32px 32px;
        padding: 160px 32px 32px;
        @include media-breakpoint-up(md) {
            // margin-top: 80px;
            padding: calc(720px / 2) calc((720px / 12) * 2) 32px;
            padding: 160px calc((720px / 12) * 2) 32px;
        }
        @include media-breakpoint-up(lg) {
            margin-top: 80px;
            padding: 80px calc((960px / 12) * 1);
        }
        @include media-breakpoint-up(lg) {
            margin-top: 80px;
            padding: 80px calc((960px / 12) * 2);
        }
        @include media-breakpoint-up(xl) {
            padding: 80px calc(((1200px / 12) * 2) + 24px);
            padding: 80px calc(((100vw / 12) * 2) + 0px);
        }
        @media (min-width: 1464px) {
            padding: 80px calc((1464px / 12) * 2);
        }
    }
    .card-body {
        padding: 0;
    }
    h3 {
        margin-bottom: 12px;
    }
    .sm-2 {
        color: $primary;
        margin-bottom: 24px;
    }
    .contact {
        display: flex;
        @include media-breakpoint-down(sm) {
            flex-direction: column;
            a:first-child {
                margin-bottom: 8px;
            }
        }
        a {
            display: flex;
            align-items: center;
            font-weight: $font-weight-bold;
            &:first-child {
                margin-right: 40px;
            }
            span {
                margin-right: 12px;
            }
        }
    }
    .btn {
        margin-top: 32px;
    }
    .text-icon {
        margin-top: 12px;
        &:last-of-type {
            @include media-breakpoint-down(md) {
                margin-bottom: 8px;
            }
        }
    }
}
