//  Desktop Submenu - without ".mobileNav"
// -----------------------------------------------------------------------------

#main-nav {

    @include media-breakpoint-up(md) {
        position: absolute;
        right: 0;
        z-index: 2;
        // z-index: 1000;

        &.no-search {
            right: 42px;
        }

        .navbar-nav {
            align-items: center;
        }

        .collapsing,
        > ul > li > .collapse {
            position: absolute;
            // top: 176px;
            z-index: 1;
            overflow: scroll;
            height: calc(100vh - 100px);
            top: 100px;
            padding-top: 76px;
            padding-bottom: 64px;
            &::-webkit-scrollbar {
                display: none;
            }
            .fixed & {
                top: 64px;
                height: calc(100vh - 64px);
            }
        }
    }

    .back a {
        @include media-breakpoint-up(xl) {
            font-size: 20px;
        }
    }
}
// @media (min-width: 1200px) {
// nav.navbar {
@include media-breakpoint-up(xl) {
    nav.navbar:not(.mobileNav) {
        .navbar-toggler {
            display: none;
        }

        .navbar-nav > .zoom-social-icons-widget {
            display: none;
        }

        .navbar-brand img {
            height: 32px;
            width: auto;
            margin-left: 12px;
        }

        .btn-close {
            opacity: 0;
            margin-left: 48px;
            pointer-events: none;
            padding: 0;
            margin-right: 20px;
        }

        div.collapse {
            left: 0;
            width: 100% !important;
            transition: all 0s;
            transition-duration: 0s !important;
            // height: 100vh !important;
            transition: height 0s ease;
            // &.show {
            //     // display: flex;
            //     width: 100% !important;
            //     transition: all 0s;
            //     transition-duration: 0s !important;
            //     height: 100vh !important;
            //     transition: height 0s;
            // }
            .card,
            ul {
                // max-width: 49% !important;
                // width: 49% !important;
                display: inline-flex;
                flex-direction: column;
                height: 100%;
                // height: auto;
                // li {
                //     padding: 0;
                // }
            }

            .inner,
            .inner-contact {
                display: flex !important;
                // width: 100% !important;
                // width: calc(100% - 240px);
                // height: 100%;
                padding-left: 146px;
                padding-right: 146px;
                .back {
                    display: none;
                }
            }

            .navlist {
                flex: 0 0 50%;
                max-width: 50%;

                ul {
                    height: auto;
                    padding-right: 24px;
                }

                + .card {
                    width: 304px;
                    margin-left: auto;
                    flex: 0 0 50%;
                    max-width: 50%;
                    background-color: transparent;

                    picture {
                        // margin-right: 32px;
                        img {
                            width: 100%;
                            padding-right: 32px;
                            height: auto;
                        }
                    }

                    .card-body {
                        background-color: $lighterblue;
                        margin-left: 32px;
                        transform: translateY(-32px);
                        padding: 40px 32px;

                        p {
                            // color: pink;
                            &:before {
                                content: '';
                                background-color: $dark;
                                height: 3px;
                                width: 32px;
                                display: block;
                                margin-bottom: 16px;
                                // position: absolut
                            }
                        }
                    }
                }
            }

            .inner {
                flex-direction: row;
            }

            .inner-contact {
                flex-direction: column;

                .gp-section-card-contact {
                    margin-top: 0;
                    padding: 0;
                    margin-bottom: 40px;

                    // @include media-breakpoint-up(lg) {
                        margin-top: 80px;
                    // }

                    h5 {
                        color: $black;
                    }

                    a + p {
                        margin-top: 16px;
                    }
                    a {
                        height: auto;
                        font-weight: $font-weight-bold;

                        + a {
                            margin-top: 16px;
                        }
                    }
                }

                .card {
                    width: 100% !important;
                    max-width: 100% !important;
                    height: 100%;
                    padding: 0;
                }

                .card-body {
                    padding: 40px;
                }
            }
        }

        div.collapsing {
            width: 100% !important;
            transition: all 0s;
            transition-duration: 0s !important;
            height: 100vh !important;
            transition: height 0s;
            // div.collapsing {
            //     // height: calc(100vh - 80px) !important;
            //     transition: none;
            //     display: none;
            // }
            transition: none;
            display: none;
        }

        [aria-expanded] {
            position: relative;
            padding-bottom: 6px;

            &:after {
                content: '';
                position: absolute;
                bottom: -5px;
                left: 50%;
                height: 3px;
                background-color: $secondary;
                opacity: 0;
                width: 0;
                transform: translateX(-50%);
                transition: $transition-base;
            }
        }

        [aria-expanded="true"] {
            // position: relative;
            // box-shadow: 0 3px 0 0 $secondary;
            pointer-events: none;
            transition: $transition-base;

            &:after {
                width: 100%;
                opacity: 1;
            }
            // cursor: pointer;
        }

        .search {
            margin-right: 12px;
            .search-form {
                padding-right: 0;
                input {
                    padding-left: 16px;
                }
                button {
                    margin-top: 26px;
                }
            }

            a span {
                top: 2px;
                position: absolute;
                background-position: center;
                left: 0;
            }

            img {
                margin-left: 24px;
            }
        }
    }
}

@media (min-width: 1464px) {
    nav.navbar:not(.mobileNav) .navbar-brand img {
        margin-left: calc((100vw - 1452px) / 2);
        transition: none;
    }

    nav.navbar:not(.mobileNav) .search {
        margin-right: calc((100vw - 1452px) / 2);
    }
}
@media (min-width: 1568px) {
    nav.navbar:not(.mobileNav) .navbar-brand img {
        margin-left: 64px;
    }
    nav.navbar:not(.mobileNav) .search {
        margin-right: 64px;
    }
}
// Open Desktop Menu
// -----------------------------------------------------------------------------
// @media (min-width: 1152px) {
// nav.navbar {
@include media-breakpoint-up(xl) {
    nav.navbar:not(.mobileNav) {
        .cover {
            .btn-close {
                opacity: 1;
                pointer-events: all;
                // margin-left: 48px;
            }

            &:before {
                content: '';
                position: absolute;
                height: 200vh;
                width: 100%;
                top: 50%;
                transform: translate(-50%, -50%);
                left: 50%;
                background-color: white;
                z-index: -1;
            }
            // [aria-expanded~="true"] {
            //     box-shadow: 0 2px 0 0 $secondary;
            //
            //
            // }
            // [aria-expanded~="false"] {
            // // a {
            //     &:hover {
            //         box-shadow: 0 2px 0 0 $secondary;
            //     }
            // }
        }

        .navlist {
            > ul:first-child {
                padding-top: 0;

                :first-child {
                    padding-top: 0;
                }
            }
            // max-width: 49% !important;
            // width: 49% !important;
        }

        .collapse.show {
            .collapse {
                display: block;
            }
        }

        .inner {
            .back {
                display: none;
                // padding-bottom: 12px;
                // a {
                //     // display: none;
                //     font-size: 24px;
                //     color: $dark;
                //     pointer-events: none;
                // }
                // ~ li {
                //     padding: 8px 0;
                //     a {
                //         font-size: 20px;
                //     }
                // }
            }

            a {
                // display: none;
                font-size: 24px;
                // color: $dark;
                // pointer-events: none;
                font-weight: $font-weight-bold;
            }

            li a[data-toggle="collapse"] {
                color: $dark;
                box-shadow: none;
                padding-bottom: 12px;
                display: block;
                // font-weight: $font-weight-bold;
                + div {
                    li {
                        padding: 8px 0;
                        line-height: 0;
                    }

                    a {
                        // font-size: 20px;
                        font-size: 18px;
                        line-height: 24px;
                        // font-weight: $font-weight-bold;
                        padding-bottom: 2px;

                        &:hover {
                            box-shadow: 0 2px 0 0 rgba($secondary,1);
                            color: $primary;
                        }
                    }
                }
            }

            ~ li {
                padding: 8px 0;

                a {
                    font-size: 20px;
                }
            }

            ul li {
                padding: 12px 0;

                a {
                    font-size: 24px;
                    line-height: 28px;
                    height: auto;
                    white-space: normal;
                }
            }
        }
    }
}

body.body-cover {
    // position: fixed;
    // width: 100%;
    // overflow-y: scroll;
    overflow: hidden;
    position: static;
    // margin-right: 17px;

    #main-nav {
        z-index: 100;
         padding-right: 15px;
    }

    #page:after,
    nav.navbar:before
    {
        // &:after {
        background-color: rgba($primary, .25);
        height: 100vh;
        width: 100vw;
        top: 0;
        content: '';
        // display: block;
        left: 0;
        position: fixed;
        z-index: 10;
        transition: $transition-base;
        cursor: pointer;
        // }
    }
    nav.navbar:before {
        height: 0px;
    }
    nav.navbar.fixed:before {
        height: 64px;
    }
}

.zoom-social-icons-widget {
    @include media-breakpoint-up(xl) {
        ul.zoom-social-icons-list {
            nav.navbar:not(.mobileNav) & {
                flex-direction: row;
                justify-content: flex-end;
                margin-right: 146px;
            }
        }
        header & {
            // position: fixed !important;
            // bottom: 80px;
            // right: 64px;
            // display: none;
            // display: flex;

            li {
                padding: 0 0 0 24px;
            }
        }

        header .cover & {
            display: block;
        }
    }
}
