.table-striped {
    td,
    th,
    thead th {
        border: 0;
    }
    tbody {
        tr:nth-of-type(odd) {
            background-color: white;
        }
        tr:nth-of-type(even) {
            background-color: #e7f2fa;
        }
    }
}

table.table {
    margin-bottom: 0;

    caption {
        padding-top: 32px;

        .footer {
            margin-bottom: 0;
        }
    }
}

table:not(.table-striped) {
    th {
        background-color: $lightblue;
    }

    tbody {
        :first-child {
            border-top-color: #f6f4f2;
        }

        :first-child * {
            border-top: 0;
        }
    }
}

* {
    -webkit-overflow-scrolling: auto;
}

.table-responsive {
    margin-top: 40px;
    margin-bottom: 40px;

    @include media-breakpoint-down(sm) {
        width: calc(100vw - 24px);


    }
    &::-webkit-scrollbar-track {
        background-color: #eceeef !important;
        border-radius: 4px !important;
        height: 8px !important;
        width: 100% !important;
    }

    &::-webkit-scrollbar {
        height: 8px !important;
        background-color: #eceeef !important;
        border-radius: 4px !important;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $primary !important;
        border-radius: 4px !important;
        height: 8px !important;
    }

    ::-webkit-scrollbar {
        width: 12px !important;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
        -webkit-border-radius: 10px !important;
        border-radius: 10px !important;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px !important;
        border-radius: 10px !important;
        background: #41617D !important;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5) !important;
    }

    ::-webkit-scrollbar-thumb:window-inactive {
        background: #41617D !important;
    }
}
