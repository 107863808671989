.gp-section-image {
    position: relative;
    // overflow: hidden;
    margin-top: 80px;
    margin-bottom: 80px;
    &.v21-9 {
        // div[class^='col-'] {
        //     top: 0;
        //     position: absolute;
        // }
        .col-media {
            height: calc(100% / 21 * 9);
            // padding-top: calc((100% / 21) * 9);
            padding-top: calc(100% / 21 * 9);
            position: relative;
            // background-color: #d1d1d1;
            // @TODO: @Bruce Der calc würde ab breakpoint >= lg mit flex 66,6% aus dem grid kollidieren
            @include media-breakpoint-up(xl) {
                height: calc((((100% / 12) * 8) / 21) * 9);
                // padding-top: calc((100% / 21) * 9);
                padding-top: calc((((100% / 12) * 8) / 21) * 9);
            }
        }

    }
    &.v4-3 {
        .col-media {
            height: calc((((100% / 12) * 8) / 4) * 3);
            // padding-top: calc((100% / 21) * 9);
            padding-top: calc((((100% / 12) * 8) / 4) * 3);
            position: relative;
            // background-color: #d1d1d1;
        }

    }

    .row {
        justify-content: center;
    }

    .col-media {
        // position: absolute;
        // height: 100%;
        // min-height: calc((100% / 4) * 3);
        // background-color: red;
        // padding: 0;
        // &:before {
        //     padding-top: calc((100% / 4) * 3);
        //     position: absolute;
        //     content: '';
        // }
    }

    .card {
        background-color: transparent;
        text-align: center;

        h2 {
            margin-bottom: 0;
        }
    }

    .col-xl-4 {
        position: absolute;
        // height: 100%;
        display: flex;
        align-items: center;
        // display: none;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    img {
        // width: 100%;
        width: auto;
        height: auto;
        object-fit: contain;
        margin: 0 auto;
        // height: calc((100% / 4) * 3);
    }

    picture {
        display: flex;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: calc(100% - 24px);
        max-height: 100%;
        height: 100%;
        width: 100%;

        img {
            // width: auto;
            // height: 100%;
            // margin: auto;
            max-width: 100%;
    max-height: 100%;
        }
    }
    // &.main-text {
    //     overflow: visible;
    //     background-color: #f1f1f1;
    //     .col-media {
    //         position: absolute;
    //         height: 100%;
    //         display: flex;
    //         align-items: center;
    //         img {
    //             height: auto;
    //         }
    //     }
    //     .col-xl-4 {
    //         position: relative;
    //     }
    // }
}
