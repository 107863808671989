.gp-filter {
    background-color: $lightblue;
    // padding-top: 20px;
    // padding-bottom: 20px;
    @include media-breakpoint-up(lg) {
        padding-top: 48px;
        padding-bottom: 48px;
    }

    div[class^='col-'] {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @include media-breakpoint-down(lg) {
            padding-left: 24px;
            padding-right: 24px;
        }
    }

    button[data-toggle] {
        display: block;
        width: 100%;
        text-align: left;
        height: 64px;
        border-radius: 0;
        color: $primary;
        font-weight: $font-weight-bolder;
        padding: 0;
        background-position: center right;

        &:active,
        &:focus {
            box-shadow: 0 0 0 0.2rem rgba(0,90,142,0.25);
        }
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    .filter-reset {
        color: $primary;
        font-weight: $font-weight-bold;
        width: 100%;
        border: 0;
        height: 70px;
        padding: 0 0 0 36px;
        text-align: left;
        border-bottom: 2px solid rgba($white, .6);
        border-radius: 0;
        // width: calc(100% - 48px);
        // margin: 0 24px;
        background-position: center left;
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
    .custom-control-input {
        width: 100%;
        margin: 0;
        height: 100%;
        border: 0;
    }
    .modal {
        .modal-header {
            padding: 20px 24px;
            align-items: center;
            border-bottom: 2px solid rgba($white, .6);
            h5 {
                font-weight: $font-weight-bolder;
            }
            .icon-close {
                height: 24px;
                width: 24px;
                position: inherit;
            }
        }

        .modal-dialog {
            margin: 0;
            max-width: none;
            @include media-breakpoint-up(lg) {
                transform: none;
            }
        }

        .modal-content {
            background-color: $lightblue;
            border: 0;
            border-radius: 0;
            // max-height: 100vh;
        }

        .modal-body,
        .modal-footer {
            border: 0;
            color: $primary;
            font-weight: $font-weight-bold;
            background-position: center right 24px;
            // padding-bottom: 20px;
            padding-bottom: 84px;
        }

        .modal-footer {
            position: fixed;
            bottom: 0;
            width: 100%;
            background-color: $white;
            border-radius: 0;
            height: 64px;
            justify-content: flex-start;
            padding: 0 24px;
            z-index: 10;
            // bottom: -64px;
            bottom: 0;
            // position: absolute;
        }
        @include media-breakpoint-down(md) {
            .row {
                margin: 0;
            }

            .modal-content {
                // min-height: 100vh;
                height: calc(100vh - 64px);
                height: 100vh;
                max-height: 100vh;
                overflow-y: scroll;
                position: initial;
                // margin-top: 66px;
            }

            // .modal-body {
            //     padding: 0 0 88px;
            // }
        }
        @include media-breakpoint-between(xs, md) {
            .modal-body .row > div {
                // padding-left: 24px;
                // padding-right: 24px;
                padding: 0;
            }
        }
        @include media-breakpoint-up(lg) {
            display: block !important;
            opacity: 1;
            position: relative;
            z-index: 1;
            overflow: visible;

            .modal-content {
                background-color: transparent;
                border: 0;
            }

            .modal-footer,
            .modal-header {
                display: none;
            }
            .modal-body {
                padding: 0;
            }
        }
    }

    .lead {
        line-height: 28px;
        font-weight: $font-weight-bold;
        // @include media-breakpoint-down(lg) {
        margin-top: 12px;
        margin-bottom: 12px;
        // }

    }

    label {
        font-weight: $font-weight-bold;
        color: $primary;
        font-size: 20px;
        line-height: 24px;
        top: 0;
        left: 0;

        &:hover {
            cursor: pointer;
        }
    }

    .custom-control-input ~ .custom-control-label:before {
        top: 0;
    }

    .custom-control-input:active ~ .custom-control-label:before {
        background-color: rgba(0,90,142,0.25);
        border-color: rgba(0,90,142,0.5);
    }

    .custom-control-input:focus ~ .custom-control-label:before {
        border-color: rgba(0,90,142,0.75);
    }

    .custom-checkbox {
        display: inline-flex;
        margin-right: 2rem;
        margin-top: 6px;
        margin-bottom: 6px;
        @include media-breakpoint-down(md) {
            margin: 12px 0;
        }
        label {
            &:after {
                width: 20px;
                height: 20px;
                top: 2px;
                left: -30px;
            }
        }
        // &.focus.btn {
        //     box-shadow: none;
        // }

        &.btn {
            padding: 0;
            margin-right: 12px;
            border: 0;
            @include media-breakpoint-down(md) {
                margin: 12px 8px 4px 0;
            }
            label {
                border-radius: 16px;
                border: 2px solid $primary;
                padding: 2px 16px;
                font-size: 18px;
                margin: 0;
                position: relative;
                top: 0;
                left: 0;
                white-space: normal;
                text-align: left;

            }

            .custom-control-input {
                &:checked ~ label {
                    background-color: $primary;
                    color: $white;
                }

                &:focus ~ label {
                    box-shadow: 0 0 0 0.2rem rgba(0,90,142,0.25);
                    box-shadow: none;
                }
            }
        }

        &:not(.btn) {
            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }

    .row {
        &:last-of-type {
            .lead {
                @include media-breakpoint-up(xl) {
                    line-height: 38px;
                }
            }
        }
        //     &:first-of-type {
        //         // border-bottom: 2px solid $white;
        //         @include media-breakpoint-up(xl) {
        //             margin-bottom: 16px;
        //         }
        //     }
    }

    hr {
        // height: 2px;
        width: 100%;
        border-top: 2px solid rgba($white, .6);
        margin: 12px 0;
        @include media-breakpoint-up(xl) {
            border: 0;
            // height: 0;
            margin: 8px 0;
        }
    }
}
