.gp-search-results {
    padding-top: 64px;
    padding-bottom: 64px;
    @include media-breakpoint-up(lg) {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    article {
        padding: 24px 0 32px;
        border-bottom: 2px solid $lightblue;

        &:first-child {
            padding-top: 32px;
            @include media-breakpoint-up(sm) {
                padding-top: 40px;
            }
        }
        @include media-breakpoint-up(sm) {
            display: flex;
        }
        @include media-breakpoint-up(lg) {
            padding: 40px 0;
        }

        a {
            padding: 0;
            // @extend .h5;
            margin-bottom: 12px;
        }

        p {
            margin-bottom: 0;
        }

        strong {
            padding: 0.2em 0;
            background-color: #e7f3fa;
            font-weight: $font-weight-base;
        }
    }

    h1 {
        margin-bottom: 24px;
        @include media-breakpoint-up(md) {
            margin-bottom: 40px;
        }
    }

    h3 span {
        font-size: inherit;
        line-height: inherit;
    }

    .search-form {
        margin-bottom: 40px;
        @include media-breakpoint-up(md) {
            margin-bottom: 80px;
        }
        input {
            padding-left: 16px;
        }
        button {
            border-radius: 0;
            height: 56px;
            width: 56px;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            .icon-search {
                background: icon(icon-search, $white) no-repeat;
                a:hover & {
                    background: icon(icon-search, $white) no-repeat;
                }
            }
            @include media-breakpoint-up(md) {
                margin-top: 26px;
            }
        }
        + img {
            display: none;
        }
        @include media-breakpoint-down(sm) {
            .input-group-text, .search-field {
                margin: 0;
            }
        }
        .input-group.focus {
            .search-field {
                border-color: $secondary;
            }
        }

    }
    [type="search"] {
        color: $primary;
    }
    .entry-header {
        margin-bottom: 8px;
        @include media-breakpoint-up(sm) {
            margin-bottom: 12px;
        }
    }
    .entry-body {
        // display: flex;
    }

    .product_image {
        margin: 0 0 16px;
        @include media-breakpoint-up(sm) {
            width: calc((100% / 12) * 2);
            min-width: 116px;
            margin: 0 24px 0 0;
            max-height: 165px;
            overflow: hidden;
        }
        @include media-breakpoint-up(xl) {
            min-width: 220px;
        }

        img {
            @include media-breakpoint-down(xs) {
                height: 114px;
                width: auto;
                margin-left: 16px;
            }
        }
    }

    .form-control {
        &:focus {
            background-color: #e7f2fa;
        }
    }
}
