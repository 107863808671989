.slick-dots {
    position: absolute;
    //bottom: -25px;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100vw;
    // display: flex !important;
    display: flex !important;
    left: -12px;
    justify-content: center;
    // list-style: none;
    li {
        position: relative;
        display: inline-block;
        height: 12px;
        width: 12px;
        margin: 0 6px;
        padding: 0;
        // list-style: none;
        cursor: pointer;

        &:before {
            content: none !important;
        }

        button {
            border: 0;
            display: block;
            height: 12px;
            width: 12px;
            line-height: 0;
            font-size: 0;
            color: transparent;
            cursor: pointer;
            border-radius: 50%;
            background-color: white;
            padding: 0;

            &:focus {
                outline: none;
                // background-color: $link-hover-color;
                // border: 5px solid rgba($link-hover-color, .5);
            }
        }

        &.slick-active button {
            background-color: $primary;
        }
    }
}

.slider-nav {
    display: none;
    @include media-breakpoint-up(md) {
        display: flex;
    }

    .pagingInfo {
        font-size: 16px;
        color: $primary;
        font-weight: 800;
        transition: 0.2s all;
        letter-spacing: 1px;
        align-self: center;
    }

    .slick-arrow {
        transition: $transition-base;
        // position: absolute;
        display: flex;
        height: 32px;
        width: 32px;
        line-height: 0;
        font-size: 0;
        cursor: pointer;
        // background: #fff;
        color: transparent;
        // top: 50%;
        // transform: translate(0, -50%);
        padding: 0;
        border: none;
        outline: none;
        border-radius: 50%;
        margin: 0 24px;
        // background: white url(media/icons/ic-arrow-right-32.svg) center no-repeat;
        // background-size: 16px 12px;
        position: relative;
        &:hover {
            background-color: $link-hover-color;
            transition: $transition-base;
        }

        &:before {
            // content: url(media/icons/ic-arrow-right-32.svg);
            content: '';
            // @extend .icon-arrow-right-short;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 16px;
            height: 12px;
        }
    }

    .slick-prev:before {
        transform: translate(-50%, -50%) rotate(180deg);
    }

    .slick-arrow.slick-disabled {
        background-color: #a39f9e;
        transition: $transition-base;
        &:hover {
            background-color: #a39f9e;
        }
    }
    .slick-dots li button {
        background-color: $primary;
        transition: $transition-base;
        &:hover {
            background-color: $link-hover-color;
            transition: $transition-base;
        }
    }
}
