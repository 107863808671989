.gp-teaser-copy-side {
    margin-top: 64px;
    @include media-breakpoint-up(lg) {
        padding: 104px 0 0;
        margin-top: 16px;
    }
    @media (min-width: 1440px) {
        margin-top: 56px;
    }
    @media (min-width: 1680px) {
        padding: 160px 0 0;
        margin-top: 0;
    }
    @include media-breakpoint-down(md) {
        padding-bottom: 0;
    }
    + .gp-teaser-copy-side {
        padding-top: 0;
        .single-product & {
            padding-top: 160px;
        }
    }
    .container {
        .col-text .card {
            transform: translateY(300px);
            opacity: 0;
        }
        &.fadein-bt {
            @include media-breakpoint-down(md) {
                margin-bottom: -48px;
            }
        }
    }

    .container[style*='transform'] .col-text .card {
        transform: translateY(0px);
        transition: 1s;
        transition-delay: 0.5s;
        opacity: 1;
    }

    .row {
        position: relative;
    }

    .col-img {
        @include media-breakpoint-down(xs) {
            padding: 0;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    .col-text {
        margin-left: auto;
        right: 0;
        display: flex;
        top: 90%;
        transform: translateY(-48px);
        @include media-breakpoint-only(sm) {
            padding: 0 36px;
        }
        @include media-breakpoint-only(md) {
            transform: translateY(-64px);
            padding: 0 36px;
        }
        @include media-breakpoint-up(lg) {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            min-height: calc(100% - 128px);
        }

        .card {
            width: 100%;
            background-color: white;

            .card-body {
                padding: 20px 24px 32px;
                @include media-breakpoint-up(lg) {
                    padding: 48px;
                }
                @include media-breakpoint-up(xl) {
                    padding: 80px 0 80px calc(((((100vw - (100vw - 1464px)) - (11 * 24px)) / 12) * 1) + 24px);
                    width: calc(((((100vw - (100vw - 1464px)) - (11 * 24px)) / 12) * 5) + (12px * 7));
                }
            }
        }
    }
    // h3 {
    //     margin-bottom: 32px;
    // }
    &.text-img {
        .row {
            > :first-child {
                margin-left: auto;
            }
            .col-text {
                right: auto;
            }
        }
    }

}


.teaser-col-2 {
    .col-lg-6 {
        display: flex;
        flex-direction: column;
        @include media-breakpoint-down(xs) {
            padding: 0;
        }
    }
    .col-text {
        width: 100%;
        @include media-breakpoint-up(sm) {
            padding: 0 24px;
        }
        @include media-breakpoint-up(lg) {
            position: relative;
            top: 0;
            min-height: auto;
            transform: translateY(-64px);
            padding: 0 36px;
            height: 100%;
        }
    }
    &.gp-teaser-copy-side .col-text .card-body {
        @include media-breakpoint-up(lg) {
            padding: 40px 40px 56px;
            width: 100%;
        }
    }
}

.author-expert {
    .experts {
        @include media-breakpoint-up(xl) {
        display: flex;
    }
    }
    .card-body {
        width: 100% !important;
        @include media-breakpoint-up(xl) {
            padding: 80px calc(((((100vw - (100vw - 1464px)) - (11 * 24px)) / 12) * 1) + 24px) !important;
            // width: calc(((((100vw - (100vw - 1464px)) - (11 * 24px)) / 12) * 5) + (12px * 7));
        }

    }
    .media {
        margin-bottom: 12px;
        img {
            border-radius: 50%;
            margin-right: 16px;
            @include media-breakpoint-up(md) {
                width: 64px;
                height: 64px;
            }
        }
        @include media-breakpoint-up(lg) {
            width: 50%;
        }
    }
    .name {
        margin-top: 4px;
        margin-bottom: 0;
        @extend .sm-4;
        font-weight: $font-weight-bolder;
        @include media-breakpoint-up(md) {
            margin-top: 8px;
        }
    }

    .title {
        @extend .sm-4;
        color: $dark;
        margin-bottom: 0;
    }


    .btn {
        margin-top: 8px;
        @include media-breakpoint-up(lg) {
            margin-top: 20px;
        }
    }
}
