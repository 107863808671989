.lp-menu-primary {
  @include media-breakpoint-down(md) {
    display: none;
  }
  @include media-breakpoint-up(xl) {
    padding: 0 24px;
  }
  @include media-breakpoint-up(lg) {
    .navbar-nav {
      flex-direction: row;
      background-color: $white;
      height: 80px;
      padding: 0 24px;
      margin: -40px 24px 0;
      max-width: 1440px;
      position: relative;
      @include media-breakpoint-up(xl) {
        margin: -40px auto 0;
      }
      justify-content: center;
      .nav-item {
        @include media-breakpoint-up(lg) {
          padding: 0 24px;
        }
        display: flex;
        position: relative;
        text-align: center;
        &:not(:first-of-type):before {
          position: absolute;
          top: calc(50% - 12px);
          left: -12px;
          content: '';
          background: url("media/icons/ic-brand-points-24.svg") center no-repeat;
          height: 24px;
          width: 24px;
        }
        &:first-of-type {
          a {
            .navbar.fixed & {
              padding-left: 0;
            }
          }
        }
        .nav-link {
          height: 100%;
          font-weight: bold;
          align-items: center;
          display: flex;
          padding: 0 32px;
          font-size: 24px;
          @media (max-width: 1400px) {
            padding: 0 16px;
            font-size: 22px;
          }
          @include media-breakpoint-down(lg) {
            padding: 0;
            font-size: 20px;
          }
          .navbar.fixed & {
            font-size: 18px;
            line-height: 24px;
            font-weight: 900 !important;
            padding: 0;
            display: flex !important;
          }
        }
      }
    }
  }
  // Desktop fixed Onpage Nav
  // -------------------------------------------------------------------------------------------------------------------
  //@include media-breakpoint-down(xl) {
  //  a {
  //    .fixed & {
  //      font-size: 18px;
  //      font-weight: 900;
  //    }
  //  }
  //}
}
