.gp-section-card-category {
    margin-top: 64px;
    margin-bottom: 64px;
    overflow-x: hidden;
    @include media-breakpoint-up(md) {
        margin: 64px auto;
    }
    @include media-breakpoint-up(lg) {
        margin: 120px auto;
    }
    @include media-breakpoint-up(xl) {
        margin: 160px auto;
    }

    + .gp-teaser-image.v01 {
        @include media-breakpoint-up(lg) {
            margin-top: 56px;
        }
        @include media-breakpoint-up(xl) {
            margin-top: 136px;
        }
    }

    .container:first-of-type {
        // padding-top: 64px;
        margin-bottom: 24px;
        // margin: 32px auto 64px;
        @include media-breakpoint-up(md) {
            // margin: 64px auto;
            margin-bottom: 64px;
        }
        @include media-breakpoint-up(lg) {
            // margin: 120px auto;
            margin-bottom: 120px;
        }
        @include media-breakpoint-up(xl) {
            // margin: 160px auto;
            margin-bottom: 160px;
        }
    }

    .row {
        @include media-breakpoint-between(sm, md) {
            // margin-left: -8px;
            // margin-right: -8px;
            > div[class^='col-'] {
                padding: 0 8px;
            }
        }
    }

    .card picture {
        position: absolute;
        width: 100%;
        img {
            width: 100%;
            height: auto;
            opacity: 0.8;
            @include media-breakpoint-up(lg) {
                height: auto;
            }
        }

        &:after {
            content: '';
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
            position: absolute;
            height: 65%;
            width: 100%;
            bottom: 0;
            left: 0;
        }
    }

    .card {
        transition: 1s all;
        position: relative;
        text-align: center;
        background-color: transparent;
        height: calc(((100vw - (24px * 2)) / 16) * 9);
        margin-bottom: 16px;
        overflow: hidden;
        background-color: black;
        @include media-breakpoint-up(sm) {
            // height: calc((((100vw - (24px * 2)) / 2) / 16) * 9);
            height: calc(((((100vw - (100vw - 540px)) - (24px * 2)) / 2) / 16) * 9);
            // padding:
            margin-bottom: 16px;
        }
        @include media-breakpoint-up(md) {
            // height: calc((((100vw - (24px * 2)) / 2) / 16) * 9);
            height: calc(((((100vw - (100vw - 720px)) - (24px * 2)) / 2) / 16) * 9);
            // height: calc(((((100vw - (100vw - 720px)) - (24px * 2)) / 2) / 3) * 4);
        }
        @include media-breakpoint-up(lg) {
            height: calc(((((100vw - (100vw - 960px)) - (24px * 4)) / 4) / 3) * 4);
            margin-bottom: 0;
        }
        @include media-breakpoint-up(xl) {
            height: calc((((100vw - (24px * 4)) / 4) / 3) * 4);
        }
        @media (min-width: 1464px) {
            height: calc(((((100vw - (100vw - 1464px)) - (24px * 4)) / 4) / 3) * 4);
        }

        h4 {
            // text-align: center;
            &:after {
                content: none;
            }
        }

        h4,
        p {
            color: white;
            position: relative;
        }
        @include media-breakpoint-down(lg) {
            .card-title {
                margin-bottom: 0;
            }
        }
        @include media-breakpoint-down(lg) {
            .card-text {
                display: none;
            }
        }

        .card-body {
            display: flex;
            flex-direction: column;
            padding: 24px;

            :first-child {
                margin-top: auto;
            }
        }
    }
}

// Category Slider
// ---------------------------------------------------------------------------------------------------------------------

.gp-section-card-category {
    .card-category-slider {
        &.slick-initialized {
            margin-bottom: 40px;
            .card {
                margin-bottom: 0;
                @include media-breakpoint-up(sm) {
                    height: calc(((((100vw - (100vw - 540px)) - (24px * 2)) / 2) / 3) * 4);
                }
                @include media-breakpoint-up(md) {
                    height: calc(((((100vw - (100vw - 720px)) - (24px * 2)) / 2) / 3) * 4);
                }
                @include media-breakpoint-up(lg) {
                    height: calc(((((100vw - (100vw - 960px)) - (24px * 4)) / 4) / 3) * 4);
                }
                @include media-breakpoint-up(xl) {
                    // height: calc((((100vw - (24px * 4)) / 4) / 3) * 4);
                    height: calc(((((100vw - 24px) / 4) - 22px) / 3) * 4);
                }
                @media (min-width: 1464px) {
                    height: calc(((((100vw - (100vw - 1464px)) - (24px * 4)) / 4) / 3) * 4);
                }
            }
        }
    }
    .slick-list {
        overflow: visible;
    }
    @include media-breakpoint-up(xs) {
        .slider-nav {
            display: flex;
            justify-content: center;

            .pagingInfo:only-child {
                display: none;
            }
        }
    }

    .slick-arrow {
        background-color: $primary;
        height: 48px;
        width: 48px;

        &.slick-disabled {
            background-color: #a39f9e;
        }

        &:before {
            content: '';
            background: icon(icon-arrow-right, $white) no-repeat;
            width: 24px;
            height: 24px;
            transition: all 0.2s ease-in-out;
        }
    }

    .slick-dots {
        display: none !important;
    }
    .img-bottom-left + .container {
        padding-top: 0px;
    }
}


// Background images
// -----------------------------------------------------------------------------

.gp-section-card-category {
    @include media-breakpoint-up(lg) {
        .img-middle-left+.container {
            padding-top: 0px;
        }
    }
    @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;

        .container:first-of-type {
            order: 2;
        }

        .img-top-right {
            order: 1;
            position: relative;
            margin: 0 auto 40px;
            width: auto;

            img {
                position: relative;
                left: auto;
                transform: none;
            }

            + .container {
                order: 3;
                // margin-bottom: 24px;
            }
        }

        .img-middle-left {
            order: 5;
            margin-top: 24px;
            + .container {
                order: 4;
                padding-top: 24px;
                padding-top: 0;
            }
        }
    }
}
