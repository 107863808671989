header {
    .container-fluid {
        padding: 0;
    }

    ul.navbar-nav {
        flex-direction: row;

        > li {
            position: inherit;
            padding-bottom: 0;
        }

        li {
            position: inherit;

            &:before {
                content: none;
            }

            a {
                font-weight: $font-weight-bolder;
                letter-spacing: 0.3px;
                white-space: nowrap;
                font-size: 18px;
                height: 56px;
                @media (min-width: 1440px) {
                    font-size: 20px;
                }

            }
        }
    }
}
// Navbar mobile / desktop and fixed condition
// -----------------------------------------------------------------------------

nav.navbar {
    height: 64px;
    display: flex;
    align-items: center;
    padding: 0;
    transition: $transition-base;
    @include media-breakpoint-up(lg) {
        height: 100px;
        background-color: $white;
    }
    &.mobileNav {
        height: 64px;
        background-color: $white;
    }

    &.fixed {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 100;
        background-color: $white;
        height: 64px;
        transition: $transition-base;
        border-bottom: 1px solid $body-bg;
    }
}
// @include media-breakpoint-down(lg) {

.navbar-brand img {
    transition: $transition-base;
    height: 24px;
    width: auto;
    margin-left: 24px;

    .fixed &,
    .mobileNav & {
        transition: $transition-base;

        width: auto;
        margin-left: 24px;
        body:not(.theme-formula) & {
            height: 24px !important;
        }
    }
}
// }

li.search {
    a {
        padding-left: 30px;
        position: relative;

        span {
            margin-right: 12px;
        }
    }

    img {
        width: 50%;
        margin-top: 80px;
        @include media-breakpoint-down(md) {
            width: 100%;
            padding: 0 24px;
        }
    }

    h3 {
        margin-top: 40px;
        margin-bottom: 24px;
    }

    .inner {
        flex-direction: column !important;
    }

    form,
    h3 {
        padding-left: 24px;
        padding-right: 24px;
    }
    input {
        padding-left: 16px;
    }
    button {
        border-radius: 0;
        height: 56px;
        width: 56px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon-search {
            background: icon(icon-search, $white) no-repeat;
            a:hover & {
                background: icon(icon-search, $white) no-repeat;
            }
        }
    }
}

.navbar-brand {
    z-index: 2;

    img {
        width: 120px;
        height: auto;
        margin-left: 2vw;
        @include media-breakpoint-up(xl) {
            width: 180px;
        }
    }
}

.navbar-toggler {
    border: 0;
    margin-right: 8px;

    &:active,
    &:focus {
        outline: none;
    }
    .navbar-toggler-icon {
        background-size: 24px 24px;
    }
    @include media-breakpoint-up(xl) {
        display: none;
    }
    @include media-breakpoint-down(lg) {


        &[aria-expanded='true'] {
            .navbar-toggler-icon {
                background-image: url("media/icons/ic-close-24.svg");
            }
        }
    }
}
// Mobile Nav
// -----------------------------------------------------------------------------
// @include media-breakpoint-down(lg) {
//
// }
// .mobileNav {
// @media (max-width: 1151px) {
@include media-breakpoint-down(xl) {
    .mobileNav {
        overflow: hidden;
        .body-cover & {
            overflow: visible;
        }
        .navbar-brand,
        .navbar-toggler {
            z-index: 1000;
            // cursor: pointer;
        }

        .btn-close {
            display: none;
            padding: 0;
            margin-right: 42;
        }

        .navbar-toggler {
            display: block;
            margin-left: auto;
        }

        #main-nav {
            display: block !important;
            position: absolute;
            // top: 64px;
            top: 0;
            height: 100vh;
            width: 50vw;
            background-color: white;
            transform: translateX(100vw);
            display: block;
            // transition: $transition-base;
            z-index: 1;
            padding: 80px 0 0;
            overflow: hidden;
            &.collapsing,
            &.show {
                width: 50vw;
                transform: translateX(50vw);
                transition: $transition-base;
                left: 0;
                padding-right: 0;
                .navbar-nav {
                    flex-direction: column;
                    > li .zoom-social-icons-widget {
                        display: none;
                    }
                }
            }
            &.show {
                overflow-y: scroll;
            }
            li.search {
                .input-group-text, .search-field {
                    margin: 0;
                }
                .input-group.focus .search-field {
                    border-color: $secondary;
                }
                img {
                    margin-top: 48px;
                    + img {
                        margin-top: 0;
                    }
                }
            }
            li:not(.zoom-social_icons-list__item) {
                transition: $transition-base;
                transition-delay: 0.3s;
                // opacity: 1;
                padding: 0;
                width: 100%;
                flex-grow: 1;

                a:not(.sm-1),
                a:not(:only-child) {
                    &:before {
                        // @extend .icon-arrow-right;
                        background: icon(icon-arrow-right, $primary) no-repeat;
                        height: 24px;
                        width: 24px;
                        content: '';
                        position: absolute;
                        right: 24px;
                    }
                }
                a:only-child {
                    &:before {
                        content: none;
                    }
                }

                > a {
                    padding: 0 0 0 24px;
                    transition: $transition-base;
                    // opacity: 0;
                    height: 56px;
                    display: flex;
                    align-items: center;
                    border-bottom: 2px solid $lightblue;
                }

                div.collapse {
                    position: absolute;
                    background-color: $white;
                    z-index: 2;
                    // display: block !important;
                    height: calc(100vh - 80px) !important;
                    // width: 100vw;
                    width: 100%;
                    top: 80px;
                    transform: translateX(0%);
                    padding: 0;
                    // height: calc(100vh - 80px) !important;
                    transition: none;
                    transition: transform 0.2s;
                    overflow: scroll;

                    ul {
                        padding-top: 0;
                    }

                    .collapse {
                        top: 0;
                    }

                    .back {
                        background-color: $lightblue;

                        a {
                            transition: all 0.2s ease-in-out;
                            height: 56px;
                            display: flex;
                            align-items: center;
                            border-bottom: 2px solid #c8dff2;
                        }

                        &:before {
                            background: icon(icon-arrow-right, $primary) no-repeat;
                            height: 24px;
                            width: 24px;
                            content: '';
                            position: absolute;
                            transform: translateX(24px) rotate(180deg);
                            top: 15px;
                            left: 0;
                        }

                        a {
                            padding: 0;
                            justify-content: center;
                            font-size: 16px;

                            &:before {
                                // right: auto;
                                content: none;
                            }
                        }
                    }
                }

                div.collapsing {
                    // height: calc(100vh - 80px) !important;
                    transition: none;
                    display: none;
                }

                div.collapse:not(.show) {
                    // left: 100%;
                    transform: translateX(100%);
                    transition: transform 0.2s;
                }
            }

            .show .show {}
        }

        .navlist + .card {
            display: none;
        }

        .inner-contact {
            > p,
            > a {
                padding-left: 24px;
                padding-right: 24px;
            }

            > a {
                width: 100%;
                border-bottom: 2px solid $lightblue;
                display: flex;
                align-items: center;
                height: 56px;

                &:before {
                    content: none !important;
                }
            }

            > p {
                display: none;
            }

            .gp-section-card-contact {
                margin-top: 40px;
                .card {
                    margin-bottom: 20px;
                    height: auto;
                    // padding-bottom: 12px;
                    + .card {
                        margin-bottom: 40px;
                    }
                }
                .card-body {
                    padding-bottom: 32px;
                }
                a {
                    height: auto;
                    font-weight: $font-weight-bold;
                    &:before {
                        content: none !important;
                    }
                    + a {
                        margin-top: 16px;
                    }
                }
            }

            .col-lg-6 {
                margin-bottom: 20px;
            }

            .card {
                padding: 0;
                // margin-bottom: 20px;
            }

            .card-body {
                padding: 24px;
            }
        }
    }
}
// < 576px - Smartphone
@include media-breakpoint-down(xs) {
    .mobileNav #main-nav {
        width: 100vw;
        transform: translateX(100%);

        &.collapsing,
        &.show {
            width: 100vw;
            // transform: translateX(100%);
            transform: translateX(0vw);
            transition: $transition-base;

            li {
                transition: $transition-base;
                transition-delay: 0.3s;
                opacity: 1;
            }
        }
    }
}

// Social Media
// -----------------------------------------------------------------------------

#main-nav {
    .zoom-social-icons-list {
        display: flex;
        li {
            margin: 0;
        }
    }
    @include media-breakpoint-down(lg) {
        .zoom-social-icons-widget {
            display: none;
            width: 100%;
            margin-top: 32px;
            .mobileNav & {
                display: block;
            }
            li {

                padding: 0 0 0 16px;

                &:first-child {
                    padding-left: 24px;
                }
            }
        }

        &.cover {
            .zoom-social-icons-widget {
                // position: absolute;
                display: block;
            }
        }
    }
}
