.gp-section-card-ingredients {

    padding-top: 64px;
    padding-bottom: 72px;
    overflow: hidden;
    @include media-breakpoint-up(md) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @include media-breakpoint-up(xl) {
        padding-top: 144px;
        padding-bottom: 80px;
    }

    picture {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 277px;
        @include media-breakpoint-down(sm) {
            width: 50vw;
            transform: translate(-50%, -40%);
            min-width: 156px;
            img {
                width: 100%;
                height: auto;
                object-fit: contain;
            }
        }
    }

    .card-ingredients-slider {

        justify-content: center;
        // @include media-breakpoint-up(md) {
        //     margin: 0 -12px;
        // }
    }
    @include media-breakpoint-down(xs) {
        // .slick-track {
        //     padding-left: 16px;
        // }

        // .slick-slide {
        //     max-width: 85vw !important;
        //     padding: 0 8px;
        // }
    }
    .card-ingredients-slider {
        @include media-breakpoint-down(xs) {
            padding: 0 36px 0 16px;
            [class^='col'] {
                padding-left: 8px;
                padding-right: 8px;
            }
        }
        [class^="col-"] {
            &:nth-child(6n+1) .card {
                background-color: #d2f2c0;
            }
            &:nth-child(6n+2) .card {
                background-color: #bbeadc;
            }
            &:nth-child(6n+3) .card {
                background-color: #c8dff2;
            }
            &:nth-child(6n+4) .card {
                background-color: #cdd2ec;
            }
            &:nth-child(6n+5) .card {
                background-color: #ffc6c6;
            }
            &:nth-child(6n+6) .card {
                background-color: #ffe9b6;
            }
        }

    }
    .card {
        padding: 8px;
        position: relative;
        height: 100%;

        &:focus {
            outline: -webkit-focus-ring-color auto 0;
        }
    }

    .card-body {
        border: 2px solid $white;
        // overflow: visible;
        // padding-top: 80px;
        padding: 24px;
        @include media-breakpoint-up(sm) {
            // padding-top: 100px;
            padding-top: 0;
        }
        @include media-breakpoint-up(md) {
            // padding-top: 80px;
            padding-top: 0;
        }
        @include media-breakpoint-up(xl) {
            padding: 0 64px 40px;
        }

    }
    picture + h5 {
        padding-top: 80px;
        @include media-breakpoint-up(sm) {
            padding-top: 100px;
        }
        @include media-breakpoint-up(md) {
            padding-top: 80px;
        }
        @include media-breakpoint-up(xl) {
            padding-top: 128px;
        }
    }
    h5:first-child {
        padding-top: 24px;
        @include media-breakpoint-up(xl) {
            padding-top: 32px;
        }
    }
    // .bg-mint {
    //     background-color: #cae8df;
    // }

    h5,
    p {
        color: $dark;
        text-align: center;
    }

    p + p {
        margin: 0 auto;
    }

    h5 {
        color: $black;

        + p {
            margin-bottom: 16px;
        }
    }

    .footer {
        margin-top: 20px;
        @include media-breakpoint-up(md) {
            margin-top: 40px;
        }
    }

    .slick-list {
        overflow: visible;
    }

    .slick-track {
        // padding-top: 104px;
        padding-top: 64px;
        display: flex;
        align-items: stretch;

        // justify-content: center;
        @include media-breakpoint-up(xl) {
            width: 100% !important;
        }
    }
    .slick-slide {
        &:first-child {
            margin-left: auto;
        }
        &:last-child {
            margin-right: auto;
        }
        // @include media-breakpoint-up(lg) {
        //     width: calc(((100vw - 1400px) / 12) * 4) !important;
        // }
        height: auto;
        @include media-breakpoint-up(md) {
            // max-width: calc(((720px) / 12) * 6) !important;
            max-width: 720px !important;
        }
        @include media-breakpoint-up(lg) {
            max-width: calc(((960px) / 12) * 6) !important;
        }
        @include media-breakpoint-up(xl) {
            max-width: calc(((1464px) / 12) * 4) !important;
        }
    }

    .slick-dots {
        left: 0;
        @include media-breakpoint-up(md) {
            display: none !important;
        }
    }

    .slider-nav {
        justify-content: center;
        .pagingInfo:only-child {
            display: none;
        }
    }

    .slick-arrow {
        background: $primary icon(icon-arrow-right, $white) center no-repeat;
        width: 48px;
        height: 48px;
    }

    .slick-prev {
        transform: rotate(180deg);
    }
}
