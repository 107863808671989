.card {
  @include media-breakpoint-up(md) {
    &.light * {
      color: $white;
    }
    &.light {
      .btn {
        background-color: $white;
        color: $primary;
        border-color: $white;

        &:hover {
          background-color: #ECEEEF;
        }

      }
    }
  }
}

.roof-line {
  margin-bottom: 24px;
  @include media-breakpoint-down(md) {
    margin-bottom: 19px;
    line-height: 28px;
  }

  + h1,
  + h2,
  + h3,
  + h4,
  + h5,
  + h6 {
    position: relative;
    padding-top: 20px;

    &:after {
      position: absolute;
      content: '';
      height: 4px;
      width: 40px;
      top: -4px;
      left: 0;
      background-color: $primary;
    }

    @include media-breakpoint-down(md) {
      padding-top: 12px;
      margin-bottom: 16px;
      &:after {
        height: 3px;
      }
    }
  }

  // + h3 {
  //     @include media-breakpoint-down(md) {
  //         margin-bottom: 16px;
  //         + p {
  //             margin-bottom: 20px;
  //         }
  //     }
  // }
  // + h2:after {
  //     height: 4px;
  // }
}

.flip-card {
  background-color: transparent;
  perspective: 1000px;
  /* Remove this if you don't want the 3D effect */
  width: 300px;
  height: 450px;
  margin-bottom: 24px;
  @include media-breakpoint-down(md) {
    padding-left: 10px;
    padding-right: 10px;
    flex: auto;
    max-width: none;
  }
  @include media-breakpoint-up(lg) {
    min-width: 300px;
    max-width: 366px;
    max-height: 514px;
    height: 514px;
  }
  @media (min-width: 1200px) {
    height: calc(((100vw - (4 * 24px)) / 4) * 1.5);
  }
  @media (min-width: 1464px) {
    height: calc(((100vw - (100vw - 1440px)) / 4) * 1.5);
  }
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  position: absolute;
  width: calc(100% - 24px);
  @include media-breakpoint-down(md) {
    width: calc(100% - 20px);
  }
}

/* Do an horizontal flip when you move the mouse over the flip box container */
@include media-breakpoint-up(xl) {
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
}

@include media-breakpoint-down(xl) {
  .flip-card .flip-card-inner.flip {
    transform: rotateY(180deg);
  }
}

.card-back,
.card-front {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.card-front {
  background-color: $white;
  color: $primary;
  display: flex;
  flex-direction: column;

  img {
    width: auto;
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
    margin: 0 auto;
  }

  .card-header {
    background-color: $white;
    padding: 56px 20px 0;
    margin-top: 0;
  }

  .card-body {
    max-height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 68px;
    padding-top: 24px;
    margin-top: auto;
    @include media-breakpoint-up(lg) {
      padding-bottom: 76px;
      padding-top: 32px;
    }

    h5 {
      margin: 0;
      line-height: 32px;
    }
  }
}

.card-back {
  background-color: $white;

  color: $primary;
  transform: rotateY(180deg);
  padding: 8px;
  display: flex;
  flex-direction: column;
  text-align: left;

  .card-body,
  .card-footer,
  .card-header {
    background-color: $lightblue;
  }

  &.bg-white {
    .card-body,
    .card-footer,
    .card-header {
      background-color: $white;
    }
  }

  .card-header {
    padding: 40px 32px 0;
  }

  .card-body {
    padding: 0 32px;
  }

  .card-footer {
    padding: 0 32px 40px;
  }

  ul {
    padding-left: 0;
  }

  li {
    text-align: left;
    font-size: $font-size-base;
    list-style: none;
    background: url("media/icons/ic-checkmark-circle-24.svg") left 5px no-repeat;
    background-size: 24px 24px;
    position: relative;
    padding: 6px 0 6px 30px;
    line-height: 24px;
  }
}
