section.lp-image {
    padding: 64px 0 4px;
    margin: 0;
    @include media-breakpoint-up(lg) {
        padding: 104px 0 40px;
    }
    @media (min-width: 1440px) {
        padding: 160px 0 80px;
    }

    picture {
        margin: 0 auto;
    }

    img {
        width: 100%;
        height: auto;
        max-width: 75vw;
        //max-width: 520px;
        margin: 0 auto;
        @include media-breakpoint-up(sm) {
            max-width: 50vw;
        }
        @include media-breakpoint-up(xl) {
            max-width: 520px;
        }
    }
}

.img-top-right {
    img {
        position: absolute;
        top: 0;
        height: auto;
        right: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 320px;
        @include media-breakpoint-up(sm) {
            width: 50vw;
            padding: 0;
        }
        @include media-breakpoint-up(lg) {
            left: auto;
            right: 0;
            transform: translateY(-38%);
        }
    }
}

.img-middle-left {
    display: block;
    margin-top: 40px;

    + .container {
        padding-top: 48px;
        @include media-breakpoint-up(lg) {
            padding-top: 120px;
        }
    }
    @include media-breakpoint-up(lg) {
        margin-top: 0;
        position: absolute;
        left: 0;
        transform: translateY(-50%);
        width: 20vw;
        height: auto;
        max-width: 400px;
        width: 25vw;
    }

    img {
        width: 100%;
        display: block;
        @include media-breakpoint-up(sm) {
            margin: 0 auto;
            padding: 0;
        }
        @include media-breakpoint-between(sm, md) {
            width: 50vw;
        }
        @include media-breakpoint-up(lg) {
            margin: 0;
        }
    }
}

.img-bottom-left {
    display: block;
    margin-top: 40px;
    .home & {
        z-index: -1;
        order: 4;
    }
    + .container {
        padding-top: 48px;
        order:3;
        @include media-breakpoint-up(lg) {
            padding-top: 120px;
        }
    }
    @include media-breakpoint-up(lg) {
        margin-top: 0;
        position: absolute;
        left: 0;
        transform: translateY(calc((((100vw - (100vw - 1440px)) / 4) * 1.5) / -2));
    }

    img {
        width: 100%;
        display: block;
        padding: 0 24px;
        @include media-breakpoint-up(sm) {
            max-width: 600px;
            margin: 0 auto;
            padding: 0;
        }
        @include media-breakpoint-between(sm, md) {
            width: 50vw;
        }
        @include media-breakpoint-up(lg) {
            margin: 0;
        }
    }
}

.img-bottom-right {
    img {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        width: 100%;
        height: auto;
        padding: 0 24px;
        max-width: 320px;
        @include media-breakpoint-up(sm) {
            width: 50vw;
            padding: 0;
        }
        @include media-breakpoint-up(lg) {
            transform: translateY(20%);
            right: 0;
            left: auto;
        }
    }
}

.img-top-right, .img-middle-left, .img-bottom-left, .img-bottom-right {
    img {
        @include media-breakpoint-up(lg) {
            width: 18vw;
            max-width: 450px;
        }
        .lp-section-card-category & {
            @include media-breakpoint-up(md) {
                width: 260px;
                padding: 0;
            }
            @media (min-width: 1440px) {
                width: 18vw;
                max-width: 450px;
            }
        }
    }
}
