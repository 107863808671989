footer#colophon {
    background-color: $primary;
    padding-top: 40px;
    padding-bottom: 54px;
    @include media-breakpoint-up(md) {
        padding-top: 48px;
        padding-bottom: 120px;
    }
    @include media-breakpoint-down(sm) {
        .container-fluid {
            max-width: 540px;

            .col {
                padding: 0 24px;
            }
        }
    }

    .nav-item {
        padding: 4px 0;
    }

    a,
    p {
        line-height: 24px;
        font-size: 16px;
    }

    .footer-nav .row [class^='col'] {
        li {
            padding: 0;
        }
        &:not(:first-child) {
            p {
                padding-bottom: 0;
            }
            a {
                box-shadow: none;
                @include media-breakpoint-up(md) {
                    margin: 4px 0;
                    display: inline-block;
                }

                & :hover {
                    box-shadow: none;
                }
            }
        }

        &:first-child {
            a {
                box-shadow: 0 1px 0 0 $white;

                & :hover {
                    box-shadow: 0 1px 0 0 $white;
                }
            }
        }
    }

    .brand-img {
        height: 32px;
        @include media-breakpoint-up(md) {
            height: 40px;
        }
    }

    *:not(.btn-light) {
        color: white;
    }

    #footer-widget {
        > div {
            margin-top: 48px;
            > section + section {
                margin-top: 48px;
            }
        }

        .zoom-social-icons-list--without-canvas {
            margin: 20px 0 0;

            li {
                margin: 0 8px;
            }

            :first-child {
                margin-left: 0;
            }
        }

        .zoom-social_icons-list-span {
            background-color: white;
        }
    }

    .sm-1,
    h4 {
        position: relative;
        padding-top: 11px;
        @include media-breakpoint-up(lg) {
            padding-top: 19px;
        }

        &:before {
            position: absolute;
            content: '';
            height: 3px;
            width: 40px;
            top: 0;
            left: 0;
            background-color: $white;
        }
    }

    input {
        border-color: white;
        background-color: transparent;
        color: white;
        font-weight: 700;
        height: 40px;
        max-width: 348px;
    }

    ::placeholder {
        color: white;
        font-weight: 700;
    }
}

footer {
    ul {
        flex-direction: column;

        li {
            padding: 0;

            &:before {
                content: none;
            }
        }
    }
}
