section.gp-stage-subpage {
    height: 100%;
    background-color: $white;
    @include media-breakpoint-up(md) {
        height: 55vw;
        max-height: 840px;
    }
    @include media-breakpoint-up(lg) {
        height: 48vw;
        max-height: calc(100vw / 2);
    }
    @include media-breakpoint-up(xl) {
        // max-height: 640px;
        max-height: 576px;
    }
    @include media-breakpoint-down(sm) {
        picture {
            min-height: calc((100vw / 16) * 9);
        }
    }

    .container,
    .row {
        height: 100%;
        @include media-breakpoint-up(lg) {
            &.center {
                justify-content: center;
            }
            &.right {
                justify-content: flex-end;
            }
        }
    }

    .row {
        align-items: center;
    }

    .card {
        padding: 56px 64px 64px;
        @include media-breakpoint-down(sm) {
            padding: 24px 0 32px;
        }
    }

    .card-body {
        padding: 0;
    }
    h2 {
        @include media-breakpoint-down(sm) {
            margin-bottom: 16px;
        }
    }
    p {
        margin-bottom: 20px;
        @include media-breakpoint-up(md) {
            margin-bottom: 24px;
        }
    }

    hr {
        background-color: $lightblue;
        margin-top: 32px;
    }

    .coupon {
        color: $dark;
        display: flex;

        span {
            width: 50%;
            display: flex;
            //align-items: center;
            line-height: 28px;
            &:first-of-type {
                padding-right: 32px;
            }

            &:before {
                content: '';
                width: 20px;
                min-width: 20px;
                height: 20px;
                margin-top: 2px;
                // background-color: $gray-500;
                margin-right: 8px;
                // height: 24px;
                // width: 24px;
            }
        }
        .coupon-value:before {
            background: icon(icon-present, $dark) no-repeat;
        }
        .coupon-date:before {
            background: icon(icon-calendar, $dark) no-repeat;
        }
        @include media-breakpoint-down(sm) {
            flex-direction: column;

            span {
                width: 100%;

                &:first-of-type {
                    margin-bottom: 4px;
                }
            }
        }
    }
}
