.gp-stage-home,
.gp-stage-home .slick-slide,
.gp-stage-home .slick-slider {
    @include media-breakpoint-up(md) {
        height: 65vw;
        max-height: 840px;
    }
    @include media-breakpoint-up(lg) {
        height: 50vw;
        max-height: calc(100vw / 2);
    }
    @include media-breakpoint-up(xl) {
        max-height: 800px;
    }
}

.gp-stage-home {
    .slick-slide,
    .slick-slider {
        position: relative;
        padding: 0;
    }

    .h1 {
        margin-bottom: 48px;
        font-weight: $font-weight-bolder;
        @include media-breakpoint-down(md) {
            font-size: calc(1.125rem * (40 / 18));
            line-height: 1.2;
            margin-bottom: 32px;
        }
    }

    img {
        width: 100%;
        height: auto;
        @include media-breakpoint-up(md) {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
        }
    }

    .card {
        background-color: $white;
        max-width: 1464px;
        width: 100%;
        @include media-breakpoint-up(md) {
            background-color: transparent;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        @include media-breakpoint-up(xl) {
            width: 65%;
        }

        .card-body {
            text-align: center;
            padding: 32px 24px;

            &.light {
                @include media-breakpoint-up(md) {
                    div, h1, h2 {
                        color: #FFF;
                    }
                }
            }
        }
    }

    .slick-arrow {
        @include media-breakpoint-down(sm) {
            display: none !important;
        }
        background-color: $primary;

        &:before {
            background: icon(icon-arrow-right-short, $white) no-repeat;
            height: 12px;
            width: 16px;
        }
    }

    &.light {
        .slick-arrow {
            &:before {
                background: icon(icon-arrow-right-short, $primary) no-repeat;

            }
        }
    }

    .slick-dots {
        left: 0;
        width: 100%;
        top: calc(((100vw / 3) * 2) - 36px);

        li {
            button {
                background-color: $primary;
                opacity: 0.5;
            }
            &.slick-active button {
                opacity: 1;
            }
        }
        @include media-breakpoint-up(md) {
            top: calc(100vw - 36px);
            position: relative;
            top: 0;
        }
        @include media-breakpoint-only(sm) {
            top: calc(((100vw / 3) * 2) - 36px);
        }
    }

    .slider-nav {
        display: flex;
        @include media-breakpoint-up(md) {
            position: absolute;
            bottom: 40px;
            left: 50%;
            transform: translateX(-50%);
        }
        // @include media-breakpoint-down(sm) {
        //     // display: block;
        //     // top: calc(100vw - 36px);
        //     top: calc(((100vw / 3) * 2) - 36px);
        //     left: 0;
        //     width: 100%;
        //     height: 12px;
        // }
    }
}
