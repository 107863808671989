.section-image-copy {
  background-color: $white;
  padding-top: 40px;
  padding-bottom: 40px;

  img {
    width: 100%;
    height: auto;
  }

  figcaption {
    margin: 24px 16px 0;
    @include media-breakpoint-up(xl) {
      margin: 24px 40px 0 calc(((100% - (5 * 24px)) / 6) + 24px);
    }
  }

  &:not(.video) .card-body {
    padding: 0;
    @include media-breakpoint-up(xl) {
      padding: 80px 0 0;
    }
  }


  &:not(.image-pair) .img-right {
    @include media-breakpoint-up(lg) {
      flex-direction: row-reverse;

      .col-text {
        margin-left: 0;
        margin-right: auto;
      }

      figcaption {
        @include media-breakpoint-up(xl) {
          margin: 24px calc(((100% - (5 * 24px)) / 6) + 24px) 0 40px;
        }
      }
    }
  }
}

[class*='image-copy'] {
  .col-media {
    @include media-breakpoint-down(md) {
      order: 1;
      padding: 0 12px;
    }
  }

  .col-text {
    @include media-breakpoint-down(md) {
      order: 0;
      margin-bottom: 32px;
      padding: 0 12px;
    }
  }
}

.image-pair {
  .row {
    position: relative;
  }

  .card .row:first-of-type {
    margin-bottom: 0;
    justify-content: center;
    @include media-breakpoint-up(lg) {
      margin-bottom: 80px;
    }
  }

  .col-media {
    @include media-breakpoint-up(lg) {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
      margin-left: auto;
    }

    &:last-of-type {
      margin-top: 0;
      @include media-breakpoint-down(md) {
        margin-top: 192px;
      }
      @include media-breakpoint-down(md) {
        img {
          transform: translateX(calc((100vw - 100%) / 2));
        }
      }
    }

    &:first-of-type {
      flex: 0 0 calc((((100% - (0px)) / 12) * 8) + 24px);
      max-width: calc((((100% - (0px)) / 12) * 8) + 24px);
      position: absolute;
      @include media-breakpoint-up(lg) {
        flex: 0 0 50%;
        max-width: 50%;
      }
      @include media-breakpoint-down(md) {
        // z-index: 1;
        left: calc(((100vw - 100%) / -2) - 12px);
      }
    }
  }

  figure {
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: column-reverse;

      figcaption {
        padding: 80px 40px 24px calc(((100% / 7) * 3) + (12px));
        margin: 0;
      }
    }
    @include media-breakpoint-up(lg) {
      figcaption {
        min-height: 170px;
        // padding: 80px 40px 24px calc(((100% / 7) * 3) + (12px));
      }
    }
  }

  .img-left {
    @include media-breakpoint-up(lg) {
      .col-media:first-of-type {
        right: 0;
      }

      .col-media:last-of-type {
        margin-left: 0;

        figcaption {
          padding: 80px calc(((100% / 7) * 3) + (12px)) 24px 40px;
        }
      }
    }
  }
}

.copy {
  .image-copy {
    margin: 80px 0;

    > .row [class^='col'] {
      @include media-breakpoint-down(md) {
        padding: 0 12px;
      }
    }

    .card-body {
      padding: 0;
    }

    @include media-breakpoint-up(md) {
      .img-right {
        flex-direction: row-reverse;
      }

      figure {
        margin: 0 40px 0 0;
      }

      figcaption {
        width: 100%;
        padding-left: 40px;
        margin-top: 16px;
      }
    }
  }
}

.section-image-fullwidth {
  background-color: $white;

  .row {
    justify-content: center;
  }

  picture {
    overflow: hidden;
    position: relative;
    display: block;
    height: 50vw;
    // @include media-breakpoint-up(xl) {
    @media (min-width: 1464px) {
      max-height: 840px;
      // height: 840px;
      height: 65vw;
    }
  }

  img {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
  }
}

section[class*='image'] {
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    // min-height: 100%;
  }
}

.section-image-8col {
  padding-top: 64px;
  padding-bottom: 64px;
  @include media-breakpoint-up(lg) {
    padding-top: 160px;
    padding-bottom: 160px;
  }
  display: flex;
  flex-direction: column;

  .row {
    justify-content: center;
  }

  .gp-intro .lead {
    margin-bottom: 24px;
  }

  figcaption:not(:empty) {
    @include media-breakpoint-up(lg) {
      width: calc(((1440px / 12) * 6) - 12px);
      margin: 24px auto 0;
    }
  }

  .btn-primary {
    margin-left: auto;
    margin-right: auto;
  }
}

.section-video-12col {
  padding-top: 64px;
  padding-bottom: 64px;
  @include media-breakpoint-up(lg) {
    padding-top: 160px;
    padding-bottom: 160px;
  }
}

//.embed-responsive-16by9, .embed-responsive-4by3 {
  .embed-responsive {
  &:before {
    background-color: black;
  }
  .playButton, .replayButton {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    cursor: pointer;
    border: 0;
    transition: all $transition-base;
    opacity: 1;
    background-color: black;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: $transition-base;
    }
    //&:before {
    //  background-color: rgba(0,0,0,.75);
    //  height: 68px;
    //  width: 68px;
    //  border-radius: 50%;
    //}
    &:after {
      left: calc(50% + 3px);
      width: 92px;
      height: 92px;
      background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192" height="92" width="92"%3E%3Cpath d="M180.3 53.4c-2-7.6-8-13.6-15.6-15.7C151 34 96 34 96 34s-55 0-68.8 3.7c-7.6 2-13.5 8-15.6 15.7C8 67.2 8 96 8 96s0 28.8 3.7 42.6c2 7.6 8 13.6 15.6 15.7C41 158 96 158 96 158s55 0 68.8-3.7c7.6-2 13.5-8 15.6-15.7C184 124.8 184 96 184 96s0-28.8-3.7-42.6z" fill="rgba(0,0,0,.75)"/%3E%3Cpath fill="%23fff" d="M78 122.2L124 96 78 69.8z"/%3E%3C/svg%3E');
      transition: $transition-base;
    }
    &:hover {
      //.posterimg {
      //  height: 105%;
      //}
      transition: $transition-base;
      &:after {
        transition: $transition-base;
        background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192" height="92" width="92"%3E%3Cpath d="M180.3 53.4c-2-7.6-8-13.6-15.6-15.7C151 34 96 34 96 34s-55 0-68.8 3.7c-7.6 2-13.5 8-15.6 15.7C8 67.2 8 96 8 96s0 28.8 3.7 42.6c2 7.6 8 13.6 15.6 15.7C41 158 96 158 96 158s55 0 68.8-3.7c7.6-2 13.5-8 15.6-15.7C184 124.8 184 96 184 96s0-28.8-3.7-42.6z" fill="red"/%3E%3Cpath fill="%23fff" d="M78 122.2L124 96 78 69.8z"/%3E%3C/svg%3E');
      }
    }
    .posterimg {
      min-width: calc(100% + 2px);
      min-height: calc(100% + 2px);
      width: auto;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
    }
    &.hide {
      display: none;
    }
  }
  .replayButton {
    &:after {
      background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 192 192" height="92" width="92"%3E%3Ccircle cx="96" cy="96" r="77" fill="rgba(0,0,0,.75)"/%3E%3Cpath d="M96 64c17.7 0 32 14.3 32 32s-14.3 32-32 32-32-14.3-32-32c0-6.7 2.1-13 5.6-18.1" fill="none" stroke="%23fff" stroke-width="12" stroke-miterlimit="10"/%3E%3Cpath fill="%23fff" d="M81.7 64L94 53.6v20.8z"/%3E%3Cpath fill="%23fff" d="M91 61.1V67l-3.9-3 3.9-2.9m6-12L77 64l20 14.9V49.1z"/%3E%3C/svg%3E');
    }
    &:hover {
      &:after {
        background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 192 192" height="92" width="92"%3E%3Ccircle cx="96" cy="96" r="77" fill="red"/%3E%3Cpath d="M96 64c17.7 0 32 14.3 32 32s-14.3 32-32 32-32-14.3-32-32c0-6.7 2.1-13 5.6-18.1" fill="none" stroke="%23fff" stroke-width="12" stroke-miterlimit="10"/%3E%3Cpath fill="%23fff" d="M81.7 64L94 53.6v20.8z"/%3E%3Cpath fill="%23fff" d="M91 61.1V67l-3.9-3 3.9-2.9m6-12L77 64l20 14.9V49.1z"/%3E%3C/svg%3E');
      }
    }
  }
}

.gp-section-card-category + .section-video-12col {
  padding-top: 0px;
  @include media-breakpoint-up(lg) {
    padding-top: 80px;
  }
}

.section-image-copy.video {
  .card-body {
    padding: 0;
  }

  iframe {
    padding: 80px 0 0;
  }
}
