body.error404 {
    .site-content, #content {
        background-color: $white;
    }
}

section.error-404 {
    padding-top: 120px;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .row {
        justify-content: center;
    }
    h1, p {
        text-align: center;
    }
    .search-form {
        margin-bottom: 40px;
        @include media-breakpoint-up(md) {
            margin-bottom: 80px;
        }
        input {
            padding-left: 16px;
        }
        button {
            border-radius: 0;
            height: 56px;
            width: 56px;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            .icon-search {
                background: icon(icon-search, $white) no-repeat;
                a:hover & {
                    background: icon(icon-search, $white) no-repeat;
                }
            }
            @include media-breakpoint-up(md) {
                margin-top: 26px;
            }
        }
        + img {
            display: none;
        }
        @include media-breakpoint-down(sm) {
            .input-group-text, .search-field {
                margin: 0;
            }
        }
        .input-group.focus {
            .search-field {
                border-color: $secondary;
            }
        }

    }
    [type="search"] {
        color: $primary;
    }

}
