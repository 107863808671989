.download {
    @include media-breakpoint-up(md) {
        column-count: 2;
        column-gap: 24px;
    }
    .icon-download {
        margin-right: 16px;
    }
    .media {
        // max-width: 50%;
        break-inside: avoid-column;
        * {
            transition: $transition-base;
        }
        section.copy & {
            margin-bottom: 0;
        }
    }
    .media-body {
        margin-bottom: 24px;
    }
    .sm-1 {
        color: $link-color;
        font-weight: $font-weight-bold;
        margin-top: 6px;
    }
    .sm-3 {
        color: $gray-700;
        text-transform: uppercase;
    }
    .media:hover {
        .sm-1 {
            color: $link-hover-color;
        }
        .icon-download {
            background: $link-hover-color icon(icon-download, $white) center center no-repeat;
            background-size: 45%;
        }
        * {
            transition: $transition-base;
        }
    }
}
