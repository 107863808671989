.gp-section-product-info {
    background-color: $white;
    padding-top: 64px;
    padding-bottom: 64px;
    @include media-breakpoint-up(lg) {
        padding-top: 120px;
        padding-bottom: 120px;
    }
    .produktubersicht_anmerkungen {
        margin-top: 30px;
        p {
            font-size: 16px;
        }
    }
    .gp-section-card-ingredients {
        padding-top: 60px;
        @include media-breakpoint-up(lg) {
            padding-top: 124px;
        }
    }
    ul.nav-tabs ~ .entry-content {
        h1, h2, h3, h4 {
            font-size: calc(1.125rem * (24 / 18));
            @include media-breakpoint-up(md) {
                font-size: 28px;
                line-height: 40px;
            }
        }
    }
    .card-ingredient {
        .card-body {
            padding-top: 76px;
        }
        h5 {
            padding-top: 12px;
            @include media-breakpoint-up(lg) {
                padding-top: 24px;
            }
        }
    }
    .nav-tabs {
        @include media-breakpoint-down(sm) {
            flex-wrap: nowrap;
            overflow-x: scroll;
            width: calc(100% + 24px);
            // &:-webkit-scrollbar {
            //     display: none;
            // }
        }
        @include media-breakpoint-only(md) {
            width: calc(100% + 36px);
        }
    }
    .wc-tabs-wrapper {
        @include media-breakpoint-up(lg) {
            min-height: 520px;
        }
    }
    .entry-content {

        li, li * {
            font-weight: $font-weight-base !important;
            font-size: 18px !important;
            line-height: $line-height-base !important;
            letter-spacing: 0;
            // color: $dark !important;
            @include media-breakpoint-up(lg) {
                font-size: 20px !important;
            }
        }
        ul {
            margin-bottom: 40px;
        }
    }

    @media (min-width: 1440px) {
        div[class^='col-'] {
            margin-left: 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

}
