.btn-light {
    color: $primary;
    &:hover {
        color: $link-hover-color;
        background-color: $gray-100;
        border-color: $gray-100;
    }
}

.btn {
    white-space: nowrap;
}

.btn-lg {
    @include media-breakpoint-up(md) {
        font-size: 20px;
        line-height: 24px;
        padding: 16px 36px;
    }
}

.gp-back-to-top {
    padding-top: 40px;
    padding-bottom: 24px;
    position: relative;
    @include media-breakpoint-up(lg) {
        padding-bottom: 40px;
        padding-top: 80px;
    }

    .col {
        display: flex;
    }
    img {
        height: 40px;
        width: 24px;
    }

    a {
        background-color: $primary;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-content: center;
        margin-left: auto;
    }
    // .search-results & {
    //   background-color: white;
    // }
    // &:before {
    //     content: '';
    //     background-color: $white;
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     right: 0;
    //     bottom: 0;
    // }
}

.btn-light:not(.btn-sm),
.btn-primary {
    height: 48px;
    line-height: 24px;
}

button.icon-close {
    border: 0;
}

.container .row:last-of-type {
    .col {
        display: flex;

        .btn-primary {
            margin: 16px auto 0;
            @include media-breakpoint-down(sm) {
                margin: 8px auto 0;
            }
        }
    }
}

.btn-icon {
    padding-left: 26px;
    display: inline-flex;
    align-items: center;
    // font-weight: $font-weight-bold;

    [class^='icon-'] {
        margin-right: 14px;
    }

    &:hover .icon-edit {
        background: icon(icon-edit, $white) center center no-repeat;
        transition: $transition-base;
    }
}

.text-icon {
    display: flex;
    align-items: center;
    font-weight: $font-weight-bold;


    [class^='icon-'] {
        margin-right: 10px;
    }
}

*:focus,
.btn:focus {
    box-shadow: none !important;
}
