ol:not(.breadcrumb),
ul {
    // font-size: 20px;
    line-height: 32px;

    @include media-breakpoint-up(md) {
        font-size: 20px;
    }
    p, a, span {
        margin-bottom: 0;
    }
}
// UL Listen
ul {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;

    li {
        padding: 0 0 8px 30px;
        position: relative;

        &:not([class*='item']) {
            &:before {
                content: "";
                position: absolute;
                width: 4px;
                height: 4px;
                display: inline-block;
                left: 16px;
                top: 12px;
            }
        }

        &:last-child {
            padding-bottom: 0;
        }

        ul {
            padding-top: 8px;
        }
    }

    // &.unordered, &.index {
        li, &.index li {
            &:before {
                background: $secondary;
                left: 16px;
                top: 14px;
            }
        }
    // }
}


// OL Listen
ol:not(.breadcrumb) {
    margin-left: 0;
    padding-left: 0;
    list-style: none;
    counter-reset: li;

    li {
        padding: 0 0 8px 30px;
        counter-increment: li;
        position: relative;

        &:last-child {
            padding-bottom: 0;
        }

        ol {
            padding-top: 8px;
        }

        &:before {
            content: counter(li) "";
            display: inline-block;
            position: absolute;
            left: 0;
            // right: 0;
            top: 0;
            // color: $gray;
            font-weight: 700;
            left: -20px;
            width: 2em;
            text-align: right;
            font-weight: $font-weight-bolder;
            color: $secondary;
        }

        ol {
            counter-reset: listStyle;

            li {
                counter-increment: listStyle;

                &:before {
                    content: counter(listStyle, upper-alpha) ".";
                }
            }
        }
    }
    &.footer {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: .3px;
        li {
            &:before {
                content: "[" counter(li) "]";
                left: -8px;
                font-weight: $font-weight-base;
                color: $dark;

            }
        }

    }
}
// UL Liste mit Checkicons
ul.checklist {
    li {
        padding: 0 0 8px 32px;

        &:before {
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            // border: 2px solid $primary;
            border-radius: 50%;
            background: $blue icon(icon-checkmark, $white) center center no-repeat;
            background-size: 10px;
            top: 6px;
            left: 0;
        }
    }
}
