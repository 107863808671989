section.gp-image {
  padding: 64px 0 4px;
  margin: 0;
  @include media-breakpoint-up(lg) {
    padding: 104px 0 40px;
  }
  @media (min-width: 1440px) {
    padding: 160px 0 80px;
  }
  picture {
      margin: 0 auto;
  }
  img {
    width: 100%;
    height: auto;
    max-width: 75vw;
    @include media-breakpoint-up(sm) {
      max-width: 50vw;
    }
    @include media-breakpoint-up(xl) {
      max-width: 520px;
    }
  }
}


.avatar {
    border-radius: 50%;
    overflow: hidden;
    align-self: center;
    margin-right: 32px;
}


// Decorative images
// -------------------------------------------------------------------------------------------------------------------

.img-top-right {
    position: absolute;
    width: 100%;
}
.img-middle-left {
    z-index: -1;
    margin-top: 0;
}
// .img-top-right {
//   img {
//     position: absolute;
//     top: 0;
//     height: auto;
//     right: auto;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     width: 100%;
//     padding: 0 24px;
//     max-width: 320px;
//     @include media-breakpoint-up(sm) {
//       width: 50vw;
//       padding: 0;
//     }
//     @include media-breakpoint-up(lg) {
//       width: 25vw;
//       left: auto;
//       right: 0;
//       transform: translateY(-38%);
//       max-width: 450px;
//     }
//   }
// }
//
// .img-middle-left {
//   display: block;
//   margin-top: 40px;
//
//   + .container {
//     padding-top: 48px;
//     @include media-breakpoint-up(lg) {
//       padding-top: 120px;
//     }
//   }
//   @include media-breakpoint-up(lg) {
//     position: absolute;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     width: 100%;
//     padding: 0 24px;
//
//     padding: 0;
//     left: 0;
//     right: 0;
//     transform: translateY(-50%);
//     width: 20vw;
//     height: auto;
//     max-width: 400px;
//     width: 25vw;
//
//   }
//   img {
//     width: 100%;
//     display: block;
//     padding: 0 24px;
//     @include media-breakpoint-up(sm) {
//       max-width: 600px;
//       margin: 0 auto;
//       padding: 0;
//       max-width: 320px;
//     }
//     @include media-breakpoint-between(sm, md) {
//       width: 50vw;
//     }
//     @include media-breakpoint-up(lg) {
//       margin: 0
//     }
//   }
// }
//
// .img-bottom-right {
//   img {
//     position: absolute;
//     bottom: 0;
//     left: 50%;
//     transform: translate(-50%, 50%);
//     width: 100%;
//     height: auto;
//     padding: 0 24px;
//     max-width: 320px;
//     @include media-breakpoint-up(sm) {
//       width: 50vw;
//       padding: 0;
//     }
//     @include media-breakpoint-up(lg) {
//       transform: translateY(20%);
//       width: 25vw;
//       right: 0;
//       left: auto;
//       max-width: 460px;
//     }
//   }
// }
