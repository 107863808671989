blockquote.blockquote {
    color: $primary;
    display: flex;
    margin-bottom: 40px;
    @include media-breakpoint-up(lg) {
        margin-bottom: 80px;
    }
    @include media-breakpoint-down(sm) {
        flex-direction: column;

        img {
            margin-bottom: 24px;
        }
    }

    picture {
        min-width: 50%;
        width: 50%;
        @include media-breakpoint-up(md) {
            width: 304px;
            min-width: 304px;
            margin-right: 62px;
        }
    }

    h6 {
        margin-bottom: 24px;
        font-weight: $font-weight-bold;
        @include media-breakpoint-up(lg) {
            font-size: 28px;
            line-height: 36px;
        }
    }

    .sm-1 {
        margin-bottom: 8px;
        font-weight: $font-weight-bold;
    }
}
