.pagination {
    display: flex;
    justify-content: center;
    padding-top: 24px;
    @include media-breakpoint-up(lg) {
        padding-top: 48px;
    }
    ul {
        display: flex;

    }
    li {
        // background-color: $lighterblue;
        // margin: 0 2px;
        // border-radius: 50%;
        padding: 0;
        // height: 40px;
        // width: 40px;
        &:before {
            content: none !important;
        }
        a, span {
            background-color: $lighterblue;
            margin: 0 2px;
            border-radius: 50%;
            padding: 0;
            height: 40px;
            width: 40px;
            display: block;
            text-align: center;
            line-height: 38px;
            font-weight: $font-weight-bolder;
        }
        span {
            background-color: $secondary;
            color: $white;
        }
        a {
            &:hover {
                background-color: $lightblue;

            }
        }
        &.next, &.prev {
            a {
                background: transparent icon(icon-arrow-right, $primary) no-repeat;
                // height: 24px;
                // width: 24px;
                background-position: center;
                &:hover {
                    background: transparent icon(icon-arrow-right, $link-hover-color) no-repeat;
                    background-position: center;
                }
            }

        }
        &.next a {
            @include media-breakpoint-up(lg) {
                margin-left: 22px;
            }
        }
        &.prev a {
            transform: rotate(180deg);
            @include media-breakpoint-up(lg) {
                margin-right: 22px;
            }
        }
        &.first, &.last {
            display: none;
        }
    }
}
