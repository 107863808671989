.modal-dialog {
  @include media-breakpoint-down(xs) {
    margin: 0;
  }
  @include media-breakpoint-up(sm) {
    max-width: 50vw;
  }
}
.modal button.icon-close {
  position: absolute;
  right: 16px;
  top: 16px;
  @include media-breakpoint-up(lg) {
    right: 40px;
    top: 40px;
  }
}
.modal-header, .modal-body {
  padding-left: 24px;
  padding-right: 24px;
  @include media-breakpoint-up(lg) {
    padding-left: 122px;
    padding-right: 122px;
  }
}
.modal-header {
  padding-top: 48px;
  @include media-breakpoint-up(lg) {
    padding-top: 80px;
  }
}
.modal-body {
  padding-bottom: 88px;
}