section.copy {
    background-color: $white;
    color: $gray-700;

    div[class^='col-'] > {
        h1 {
            margin-bottom: 40px;
        }

        h2, .h2 {
            margin-bottom: 24px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 32px;
            }
        }

        h4,
        h5,
        h6 {
            margin-bottom: 16px;
        }

        .lead {
            color: $gray-700;
            margin-bottom: 64px;
        }

        .btn:not(.share) {
            margin-bottom: 80px;
        }

        .accordion {
            margin-bottom: 40px;
        }
        ul {
            margin-bottom: 40px;
        }
    }

    > .container > .row {
        justify-content: center;
    }

    .media {
        margin-bottom: 24px;

        p {
            margin-bottom: 0;
        }
    }

    .share {
        background: icon(icon-share, $primary) center left no-repeat;
        color: $primary;
        padding: 0 0 0 34px;
        font-weight: $font-weight-bold;
        border: 0;
        margin-bottom: 12px;
        margin-top: 64px;
        @include media-breakpoint-up(lg) {
            margin-top: 120px;
            margin-bottom: 16px;
        }

        &:hover {
            background: icon(icon-share, $link-hover-color) center left no-repeat;
            color: $link-hover-color;
        }

        + .modal {
            h5 {
                margin: 0 auto;
            }

            ul {
                display: flex;
                justify-content: center;
                margin-top: 20px;
            }
            li {
                padding-left: 0;
                margin: 0 8px;
            }
        }
    }

    .author-name {
        color: $black;
    }

    img {
        width: 100%;
        height: auto;
        object-fit: contain;
        max-height: 100%;
    }

    p + figure {
        margin: 32px 0 56px;
        @include media-breakpoint-up(lg) {
            margin: 80px 0;
        }
    }

    figcaption {
        margin: 24px 16px 0;
        // @include media-breakpoint-up(sm) {
        //     margin: 24px auto 0;
        // }
        @include media-breakpoint-up(xl) {
            margin: 24px auto 0;
            width: calc(((1440px / 12) * 6) - 12px);
            width: calc(((100vw / 12) * 6) - 24px);
        }
        @media (min-width: 1464px) {
            width: calc(((1440px / 12) * 6) - 12px);
        }
    }
}

// Index Box

div.index {
    background-color: #e7f2fa;
    // padding: 32px 40px 16px;
    padding: 32px 24px 20px;
    margin-bottom: 80px;
    @include media-breakpoint-up(md) {
        padding: 24px 24px 28px;
    }

    h6 {
        margin-bottom: 24px;
    }

    a {
        font-weight: $font-weight-bold;
    }

    ul {
        margin-bottom: 0;
        @include media-breakpoint-up(md) {
            column-count: 2;
        }
    }

    li {
        padding-left: 16px;
        // margin-bottom: 12px;
        display: inline-block;
        width: 100%;
        // @include media-breakpoint-up(md) {
        //     margin: 0 12px;
        // }
        &:last-child {
            padding-bottom: 8px;
        }
        @include media-breakpoint-up(lg) {
            padding-bottom: 12px;
            &:last-child {
                padding-bottom: 12px;
            }
        }
        &:before {
            left: 0;
        }
    }
}
