.newsletter {
    padding-top: 0;
    margin-top: 0;
    .container-fluid {
        padding-left: 0;
        padding-right: 0;
        height: auto;
    }

    picture {
        width: 100%;
        left: 0;
    }
    @include media-breakpoint-up(lg) {
        img {
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            min-width: 100%;
        }
    }

    p {
        margin-bottom: 24px;
    }

    input {
        border-radius: 0;
        border: 0;
        height: 56px;
        font-weight: $font-weight-bold;
        border-bottom: 2px solid $lightblue;
        padding-left: 16px;
        @include media-breakpoint-down(md) {
            background-color: #e7f2fa;
        }
        @include media-breakpoint-up(lg) {
            &:focus {
                background-color: $white;
            }
        }
    }

    .card-body {
        margin: 0 auto;
        @include media-breakpoint-only(sm) {
            max-width: 540px;
            padding: 24px 12px 32px;
        }
        @include media-breakpoint-only(md) {
            max-width: 720px;
            padding: 24px 12px 32px;
        }
        @include media-breakpoint-down(xs) {
            padding: 24px 36px 32px;
        }
    }

    picture + div {
        @include media-breakpoint-only(lg) {
            margin-left: calc(((100vw - 960px) / 2) + 12px);
        }
        @include media-breakpoint-up(xl) {
            margin-left: calc(((100vw / 12) * 1) + 12px);
        }
        @media (min-width: 1464px) {
            margin-left: calc(((100vw - 1464px) / 2) + 12px);
            flex: 0 0 calc(((1440px / 12) * 5) + 12px);
            max-width: calc(((1440px / 12) * 5) + 12px);
        }
    }

    .modal {
        .modal-dialog {
            max-width: 100vw;
            margin-left: auto;
            margin-right: auto;
            @include media-breakpoint-up(sm) {
                max-width: 90vw;
            }
            @media (min-width: 1464px) {
                max-width: calc(((1464px / 12) * 10) - 24px);
            }
        }
        .modal-content {
            padding: 48px 24px;
            @include media-breakpoint-up(sm) {
                padding: 80px calc(((100vw / 12) * 1) + 0px) 88px;
            }
            @media (min-width: 1464px) {
                padding: 80px calc(((1464px / 12) * 1) + 0px) 88px;
            }
            > div {
                padding: 0;
            }
        }
        .close {
            top: 16px;
            right: 16px;
            @include media-breakpoint-up(sm) {
                top: 40px;
                right: 40px;
            }

            position: absolute;
            // right: calc(((1464px / 12) * 1) + 0px);

            background: icon(icon-close, $primary) center no-repeat;
            height: 48px;
            width: 48px;
            opacity: 1;
            border-radius: 50%;
            // @include ;
            &.btn-close:hover {
                background: icon(icon-close, $link-hover-color) center no-repeat;
            }
        }
        h2 {
            margin-bottom: 16px;
            // line-height: px;
            @include media-breakpoint-up(sm) {
                margin-bottom: 24px;
                // line-height: 52px;
            }
        }
        .modal-body {
            p, .form-group, .custom-checkbox {
                margin-bottom: 24px;
                @include media-breakpoint-up(sm) {
                    margin-bottom: 40px;
                }
            }
        }

        input {
            background-color: #e7f2fa;
        }
    }
}
