// Intro
// -------------------------------------------------------------------------------------------------------------------

.gp-intro {
    //margin-bottom: 32px;
    text-align: center;
    justify-content: center;
    @include make-row();
    //@include media-breakpoint-up(md) {
    //  margin-bottom: 80px;
    //}
    .lead {
        //margin: 0 auto;
        margin-bottom: 0;
    }
    &.text-light * {
        color: $white !important;
    }
    > * {
        padding-left: 24px;
        padding-right: 24px;
    }
    h2 {
        // .gp-teaser-copy-side & {
            // + p {
                // margin-bottom: 48px;
                p:blank {
                    margin-bottom: 24px;
                }
            // }
        // }
        &:only-child {
            margin-bottom: 8px;
        }
    }
    // .container & {
    //     &
    // }
    &:not(:only-child) {
        h2:only-child, h1:only-child {
            margin-bottom: 48px;
        }
        h2 + p, h1 + p {
            margin-bottom: 48px;
        }
    }
}
