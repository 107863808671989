.gp-section-product-rating-question {
    background-color: $white;
    padding-top: 48px;
    padding-bottom: 64px;
    @include media-breakpoint-up(lg) {
        padding-top: 120px;
        padding-bottom: 120px;
    }
    .icon-like:before{
        content: url("media/icons/ic-not-liked.svg");
    }
    .icon-like.liked:before{
        content: url("media/icons/ic-liked.svg");
    }
    .row {
        justify-content: center;
    }
    h2 {
        margin-bottom: 12px;
    }
    .accordion {
        margin-top: 56px;
        margin-bottom: 80px;
        &:empty, :empty {
            margin-top: 0;
        }
    }
    img.avatar {
        display: none;
    }
    .collapse, .collapsing, {
        padding-left: 0;
        padding-right: 0;
    }
    .card-body {
        p:last-child {
            margin-bottom: 0;
        }
    }
    .card-footer {
        background-color: transparent;
        padding: 0 0 32px;
        display: flex;
        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
        .btn {
            border: 0;
            padding: 0;
            margin-right: 40px;
            text-align: left;
        }
        * {
            font-size: 18px;
            line-height: 24px;
            font-weight: $font-weight-bold;
            letter-spacing: .3px;
        }
        span {
            padding: 4px 0;
        }
    }
    #reviews #comments ol.commentlist {
        li {
            border-bottom: 2px solid $lightblue;
            margin: 0;
            &:first-child {
                border-top: 2px solid $lightblue;
            }
            .comment-text {
                margin: 0;
                border: 0;
                padding: 32px 0;
                display: flex;
                flex-direction: column;

                .meta {
                    display: flex;
                    margin: 0 0 12px;
                    justify-content: space-between;
                    order: 1;
                    float: none;

                    time {
                        font-size: $font-size-base;
                        line-height: $line-height-base;
                        color: $dark;
                    }
                }

                .star-rating {
                    float: none;
                    order: 2;
                    margin-bottom: 10px;
                }

                .description {
                    order: 3;
                }
                // .comment-form-rating {
                //     [class^='star-'] {
                //         text-decoration: none;
                //     }
                // }
            }
        }

        .children {
            li {
                border: 0;
            }
        }
        + .load-more-button {
            margin-top: 40px;
        }
    }

    .comment-header {
        padding-bottom: 32px;
        @include media-breakpoint-up(lg) {
            padding-bottom: 56px;
        }
        .woocommerce-product-rating {
            .woocommerce div.product & {
                margin-bottom: 0px;
            }

        }
        button.btn-primary {
            margin-top: 24px;
            display: block;
            position: relative;
            padding-left: 60px;
            @include media-breakpoint-up(lg) {
                margin-top: 32px;
            }
            &:before, &:after {
                content: '';
                left: 24px;
                position: absolute;
                height: 2px;
                width: 20px;
                background-color: $white;
                top: 20px;
            }
            &:after {
                transform: rotate(90deg);
            }
        }

    }
        label, .label {
        display: block;
    }
    .comment-form-rating {
        label {
            position: relative;
            left: 0;
            padding: 0;
            font-size: 20px;
            top: 0;
        }
        .stars {
            margin: 24px 0 32px !important;
            @include media-breakpoint-up(lg) {
                margin: 32px 0 40px !important;
            }
            display: inline-block;
            a {
                box-shadow: none;
                &:active {
                    outline: none;
                }
            }
        }
    }

    .woocommerce-Tabs-panel--faq {
        p + h5 {
            margin-top: 40px;
            // @include media-breakpoint-up(lg) {
            //     margin-top: 80px;
            // }
        }
    }

    // input:not(.submit), .woocommerce #review_form textarea#respond, textarea {
    //     width: 75%;
    // }
    .form-submit {
        margin-top: 24px !important;
        @include media-breakpoint-up(lg) {
            margin-top: 56px !important;
        }
        input#submit {
            background-color: $primary !important;
            border-radius: 24px !important;
            height: 48px;
            transition: $transition-base;
            color: white !important;
            display: inline-block;
            width: auto;
            padding: 11px 32px 13px !important;
            margin-top: 56px;
            @include media-breakpoint-down(lg) {
                // margin: 32px 0 40px !important;
                margin-top: 14px;
            }
            &:hover {
                background-color: $link-hover-color !important;
                transition: $transition-base;
                color: $white !important;
            }
        }
    }

    .modal-dialog {
        @include media-breakpoint-down(xs) {
            margin: 0;
        }
        @include media-breakpoint-up(sm) {
            max-width: 90vw;
        }
        // label {
        //     font-size: 18px;
        // }
    }
    .modal button.icon-close {
        position: absolute;
        right: 16px;
        top: 16px;
        @include media-breakpoint-up(lg) {
            right: 40px;
            top: 40px;
        }
    }
    .modal-header, .modal-body {
        padding-left: 24px;
        padding-right: 24px;
        @include media-breakpoint-up(lg) {
            padding-left: 122px;
            padding-right: 122px;
        }
    }
    .modal-header {
        padding-top: 48px;
        @include media-breakpoint-up(lg) {
            padding-top: 80px;
        }
    }
    .modal-body {
        padding-bottom: 88px;
    }

}

// Hide image on small screen in "thank you for your comment" modal
@include media-breakpoint-down(lg) {
    #review-thank-you-modal {
        .modal-body img {
            display: none;
        }
    }
}
