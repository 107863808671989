@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=2f8f705b-d3f7-45ce-bb4e-eb35783c4546&fontids=735426,735429,735441");

// Rotis Sans Serif W01
@font-face {
  font-family: "RotisSansSerif";
  src: url("webfonts/735441/979b67f1-28b4-4d3b-ab4d-551d4eaa3827.woff2") format("woff2"),
  url("webfonts/735441/60d6e9b1-72ed-49c7-bcd9-026d74b98deb.woff") format("woff");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

// RotisSansSerifW01-Bold
@font-face {
  font-family: "RotisSansSerif";
  src: url("webfonts/735426/667fde85-ea2f-4851-a2d1-93456b293f5a.woff2") format("woff2"),
  url("webfonts/735426/a789b8d7-2c89-433b-8852-dc661d2fb383.woff") format("woff");
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

// RotisSansSerifW01-ExtraBold
@font-face {
  font-family: "RotisSansSerif";
  src: url("webfonts/735429/75ec2be4-7969-4594-9c01-fb9c2add6376.woff2") format("woff2"),
  url("webfonts/735429/13f09a40-3a24-44ca-8083-7c5e67fe802c.woff") format("woff");
  font-display: swap;
  font-style: normal;
  font-weight: 900;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
