body {
    overflow-x: hidden;
    position: relative;
    &.search-results .site {
        background-color: $white;
    }
    //main section, footer {
    //    //section {
    //        content-visibility: auto;
    //    //}
    //}
}

div[class^='col'] {
  @include media-breakpoint-down(xs) {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.content-area, .navbar.navbar-expand-xl {
    padding: 0;
}



// Individuelle Abstandkonzepte
// -----------------------------------------------------------------------------

.copy {
    + .gp-section-card-product, + .gp-section-card-contact, + .gp-section-card-author  {
        // margin-top: 88px;
        position: relative;
        &:before {
            content: '';
            display: block;
            height: 32px;
            background-color: $white;
            width: 100vw;
            @include media-breakpoint-up(lg) {
                height: 88px;
            }
        }
    }
}

article.page {
    .gp-section-card-contact:last-child {
        [class^='container'] {
            margin-bottom: 0;
        }
    }
    .gp-section-card-author {
        padding-bottom: 0;
    }
}

.gp-section-product-info {
    + .gp-teaser-image {
        // padding: 0;
        // background-color: $white;

        padding-bottom: 64px;
        @include media-breakpoint-up(lg) {
            padding-bottom: 120px;
        }
        // @include media-breakpoint-up(xl) {
        //     padding-bottom: 120px;
        // }
        @media (min-width: 1440px) {
            padding-bottom: 160px;
        }
    }
}

.gp-teaser-copy-side {
    + .gp-section-card-product {
        padding-top: 80px;
        @include media-breakpoint-up(lg) {
            padding-top: 160px;
        }
    }
}

.gp-teaser-copy-side + .gp-teaser-copy-side {
    margin-top: 48px;
}
