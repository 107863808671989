// main {
//     section.copy:first-child {
//
//     }
// }

.gp-section-ingredient-lexicon {
    background-color: $white;
    padding: 120px 0;
    > .container > .row {
        justify-content: center;
    }
    .nav-pills {

        display: flex;
        // flex-direction: row;
            margin: 0 0 80px;
        a {
            width: 40px;
            height: 40px;
            padding: 0;
            border-radius: 50%;
            background-color: $lighterblue;
            margin-right: 6px;
            list-style: none;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            margin-bottom: 6px;
            &:hover {
                background-color: $lightblue;
            }
            &.active {
                background-color: $secondary;
                color: white;
            }
        }
    }
    .section-image-copy {
        padding: 0;
        .container {
            padding: 0;
        }
        figcaption:empty {
            margin: 0;
        }
        .card-body {
            padding-top: 0;
        }
    }
    .gp-section-card-product {
        .container {
            padding: 0;
        }
        .card {
            border: 2px solid $body-bg;
        }
        [class^='col'] {
            margin-bottom: 8px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 24px;

            }
        }
    }
    .card-body {
        > h4 {
            margin-top: 32px;
            margin-bottom: 12px;
            @include media-breakpoint-up(lg) {
                margin-top: 40px;
                margin-bottom: 16px;
            }
        }
        p:empty {
            margin: 0;
        }
    }
}
