// Mobile line-heights
h1, h2, h3, .display-1 {
    line-height: 44px;
}
h4 {
    line-height: 36px;
}
h5 {
    line-height: 28px;
}

@include media-breakpoint-up(md) {
    //html {
    //  font-size: 1.25rem;
    //}
    .h1,
    h1 {
        font-size: 60px;
        line-height: 68px;
    }

    .h2,
    h2 {
        font-size: 44px;
        line-height: 52px;
        margin-bottom: 24px;
    }

    .h3,
    h3 {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 24px;
    }

    .h4,
    h4 {
        font-size: 32px;
        line-height: 40px;
    }

    .h5,
    h5 {
        font-size: 28px;
        line-height: 36px;
    }

    .h6,
    h6 {
        font-size: 24px;
        line-height: 40px;
    }

    .display-1 {
        font-size: 72px;
        line-height: 80px;
    }

    a,
    p,
    span {
        font-size: 1.25rem;
        line-height: 32px;
    }

    p {
        margin-bottom: 32px;
    }
}

.lead {
    color: $primary;
    @include media-breakpoint-up(md) {
        font-size: 24px; //$font-size-base * 1.5;
    }

    &.roof-line {
        font-weight: 700;
        font-size: 24px;
    }
}

.sm-1 {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.3px;
    font-weight: normal;
    margin: 0 0 4px;
}

.sm-2 {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.3px;
    font-weight: normal;
    margin: 0;
}

.sm-3 {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.3px;
    font-weight: normal;
}

.sm-4 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    font-weight: normal;
}

.sm-5 {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    font-weight: normal;
    margin: 0;
}

.site-content {
    p, table {
        a {
            box-shadow: 0 2px 0 0 $link-color;

            &:hover {
                color: darken($link-color, 15%);
                box-shadow: 0 2px 0 0 darken($link-color, 15%);
            }
        }
    }
}

// .list-count {
//
// }
