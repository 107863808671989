.gp-section-card-contact {

    [class^='container'] {
        &:first-child {
            margin-top: 64px;
            @include media-breakpoint-up(md) {
                margin-top: 80px;
            }
            @include media-breakpoint-up(xl) {
                margin-top: 160px;
            }

        }
        &:last-child {
            margin-bottom: 64px;
            @include media-breakpoint-up(md) {
                margin-bottom: 80px;
            }
            @include media-breakpoint-up(xl) {
                margin-bottom: 160px;
            }

        }

    }


    .card {
        padding: 8px;
        height: 100%;

    }

    @include media-breakpoint-up(xl) {
        // &:nth-last-child(2) ~ div {
        //     margin-left: auto;
        //     margin-right: auto;
        // }
        .row {
            justify-content: center;
        }
    }
    .card-body {
        border: 2px solid $lightblue;
        padding: 24px;
        @include media-breakpoint-up(lg) {
            padding: 48px 64px 64px;
        }
        @include media-breakpoint-down(xs) {
            h5 {
                margin-bottom: 12px;
            }
            > [class^='icon-'] {
                position: absolute;
                right: 23px;
                top: 26px;
                width: 40px;
                height: 40px;
            }
        }
        > [class^='icon-'] {
            display: block;
            width: 50px;
            height: 50px;
            // border: 2px solid $secondary;
            margin-bottom: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            @include media-breakpoint-up(lg) {
                transform: scale(1.25);
            }
            &:before {
                // content: 'ICON';
                content: '';
                // color: $secondary;
                // font-size: 14px;
                // font-weight: $font-weight-bolder;
            }
        }
    }
    // .col-xl-6 {
    //     .card-body {
    //         @include media-breakpoint-up(md) {
    //             padding:
    //         }
    //     }
    // }
    p {
        margin-bottom: 16px;
    }
    .telephone {
        + .btn, + p {
            margin-top: 32px;
            @include media-breakpoint-down(xs) {
                margin-top: 24px;
            }
        }
        + a {
            margin-top: 12px;
        }

        // display: block;
    }
    .container + .container {
        margin-top: 48px;
        @include media-breakpoint-down(md) {
            margin-top: 24px;
        }
        [class^='col'] {
            @include media-breakpoint-down(lg) {
                margin-bottom: 20px;
            }
            @include media-breakpoint-down(xs) {
                &:last-child {
                    margin-bottom: 0;
                }
            }
            // @include media-breakpoint-up(xl) {
            //     &:nth-last-child(2) ~ div {
            //         margin-left: auto;
            //         margin-right: auto;
            //     }
            // }
        }
    }
}
