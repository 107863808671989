.breadcrumb {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    * {
        font-size: 1rem;
        font-weight: $font-weight-bold;
        line-height: 24px;

    }
    .breadcrumb-item {
        display: flex;
        align-items: center;
        &:before {
            // transform: rotate(-90deg);
            // padding: 0;
            // width: 16px;
            // height: 16px;
            padding-top: 4px;
        }
    }
}

.nav-breadcrumb {
    .single-product & {
        @include media-breakpoint-up(sm) {
            margin: 0 52px;
        }
    }
    .col {
        align-items: center;
        display: flex;
        height: 48px;
        @include media-breakpoint-up(md) {
            height: 56px;
        }
        &:last-of-type:not(:only-child) {
            justify-content: flex-end;
            flex-grow: 0;
            span:first-of-type {
                white-space: nowrap;
                margin-right: 6px;
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        .breadcrumb-item {
            display: none;
            // &.active {
            //     display: none;
            // }
            &:nth-last-child(2) {
                display: flex;
                padding-left: 0;
                &:before {
                    // background: icon(icon-chevron, $primary) center center no-repeat;
                    // border-radius: 50%;
                    // height: 24px;
                    // width: 24px;
                    // content: '';
                    transform: rotate(180deg);
                    padding-left: .5rem;
                    padding-right: 0;
                }
            }
        }
    }
}
