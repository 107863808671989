ul.nav-tabs {
    margin-bottom: 0;
    box-shadow: inset 0 -4px 0 0 $lightblue;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    &:-webkit-scrollbar {
        display: none;
    }

    ~ .entry-content {

        .woocommerce div.product & {
            /* Set font-size for all elements (not only p/li) */
            font-size: 20px;
            margin-top: 32px;
            margin-bottom: 0;
            @include media-breakpoint-up(lg) {
                margin-top: 40px;
                margin-bottom: 0;
            }
            > :last-child {
                margin-bottom: 0;
            }
        }

    }

    li {
        &:before {
            content: none !important;
        }
        padding: 0 12px 12px;
        @include media-breakpoint-up(md) {
            padding: 0 12px 24px;
        }

        &:first-child {
            padding-left: 0;
        }
        @include media-breakpoint-up(lg) {
            &:last-child {
                padding-right: 0;
            }
        }

        a {
            line-height: 24px;
            font-weight: $font-weight-bold;
            // letter-spacing: .3px;
        }

        &.active a,
        a.active {
            box-shadow: 0 4px 0 0 #005a8e;
            padding-bottom: 13px;

            &:focus {
                box-shadow: 0 4px 0 0 #005a8e !important;
            }
            @include media-breakpoint-up(md) {
                padding-bottom: 24px;
            }
        }
    }
}

.wc-tabs-wrapper {
    // @include media-breakpoint-up(lg) {
    //     min-height: 520px;
    // }
    p:not(:empty) {
        margin-bottom: 8px;
    }

    p:empty {
        margin: 0;
    }

    p:not(.meta) span {
        font-size: 18px !important;
        @include media-breakpoint-up(lg) {
            font-size: 1.25rem !important;
        }
    }
    // Fixed tabs
    @include media-breakpoint-down(lg) {
        .nav-tabs {
            padding-top: 8px;
        }

        &.fixed {
            // * {
            //     transition: position .2s;
            // }
            padding-top: 64px;

            .nav-tabs {
                position: fixed;
                width: 100%;
                top: 64px;
                left: 0;
                z-index: 10;
                background-color: white;
                padding-left: 36px;
                padding-right: 36px;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        &.fixed {
            .nav-tabs {
                padding-left: 24px;
                padding-right: 24px;
            }
        }
    }
    // &.fixed {
    //     position: fixed;
    //     top: 64px;
    //     z-index: 10000;
    //     background-color: white;
    //     width: 100%;
    //     left: 0;
    //
    // }
}

.stars {
    a,
    a:hover {
        box-shadow: none;
        color: $primary;
    }
}
