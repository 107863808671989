section {
    &.light {
        @include media-breakpoint-up(md) {
            h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, .roof-line, .lead, p {
                color: $white !important;
            }
            .roof-line + *:after {
                background-color: $white;
            }

            .btn {
                background-color: $white;
                border-color: $white;
                color: $primary;
                &:hover {
                    background-color:  $gray-100;
                    border-color: $gray-100;
                }
            }
        }
        &.gp-teaser-image {
            @include media-breakpoint-only(md) {
                h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, .roof-line, .lead {
                    color: $primary !important;
                }
                p {
                    color: $black !important;
                }
                .roof-line + *:after {
                    background-color: $primary;
                }

                .btn {
                    background-color: $primary;
                    border-color: $primary;
                    color: $white;
                    &:hover {
                        background-color:  $link-hover-color;
                        border-color: $link-hover-color;
                    }
                }
            }
        }


        // Slider
        // ---------------------------------------------------------------------
        .slick-arrow {
            background-color: $white;
            &:hover {
                background-color:  $gray-100;
            }
            &:before {
                background: icon(icon-arrow-right, $white) no-repeat;
            }
            // .light & {
            //
            //     background-color: $white;
                &:before {
                    background: icon(icon-arrow-right, $primary) no-repeat;
                }
            // }
        }
        .slick-disabled {
            &:before {
                background: icon(icon-arrow-right, $white) no-repeat;
            }
        }
        .slick-dots li button {
            background-color: $white;
            &:hover {
                background-color:  $gray-100;
            }
        }

    }
}

.bg-lighter-blue {
    background-color: $lighterblue;
}
