.section-gallery {
    overflow-x: hidden;
    padding-top: 64px;
    padding-bottom: 64px;
    @include media-breakpoint-up(md) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @include media-breakpoint-up(md) {
        padding-top: 160px;
        padding-bottom: 160px;
    }

    .lead {
        font-weight: $font-weight-bold;
    }

    .row-slider {
        justify-content: center;
    }

    img {
        width: 100%;
        height: auto;
    }

    figure {
        // margin-left: 12px;
        // margin-right: 12px;
        margin: 32px 10px 24px;
        @include media-breakpoint-up(md) {
            margin: 48px 12px 40px;
        }

        &:not(.slick-active) {
            figcaption {
                opacity: 0;
                transition: $transition-fade;
            }
        }
    }

    figcaption:not(:empty) {
        text-align: center;
        transition: opacity 0.5s linear;
        @include media-breakpoint-up(lg) {
            width: calc(((1440px / 12) * 6) - 12px);
            margin: 24px auto 0;
        }
    }

    .slick-list {
        overflow: visible;
    }

    .slider-nav {
        justify-content: center;
        @include media-breakpoint-down(md) {
            display: none;
        }

        .slick-next,
        .slick-prev {
            background-color: $primary;
            width: 48px;
            height: 48px;

            &:before {
                background: icon(icon-arrow-right, $white) no-repeat;
                width: 24px;
                height: 24px;
            }
        }
    }

    .slick-dots {
        bottom: 0;
        display: flex;
        position: relative;
        @include media-breakpoint-up(lg) {
            display: none !important;
        }
    }
}
