.woocommerce div.product div.images img {
  position: relative;
  transform: none;
  left: auto;
  top: auto;
  max-height: 100%;
  width: auto;
  margin: 0 auto;

}

.woocommerce div.product .wp-post-image {
  max-height: calc((100vw / 12) * 10) !important;
  @include media-breakpoint-up(sm) {
    max-height: calc((100vw / 12) * 8) !important;
  }
  @include media-breakpoint-up(md) {
    max-height: calc((100vw / 12) * 6) !important;
  }
  @include media-breakpoint-up(lg) {
    max-height: 520px !important;
  }
}

.gp-stage-product {
  margin-top: 44px;
  margin-bottom: 56px;
  background-color: transparent !important;
  @include media-breakpoint-up(lg) {
    margin-top: 120px;
    margin-bottom: 112px;
  }

  .row div:first-child {
    display: flex;
    justify-content: center;
    max-height: 100%;
  }

  .woocommerce-product-gallery__wrapper {
    width: 100%;
    // @include media-breakpoint-up(lg) {
    max-width: 520px;
    max-height: 520px;
    // }
  }

  .woocommerce-review-link {
    color: $primary;
    font-weight: $font-weight-bold;
  }

  @media (min-width: 1440px) {
    div[class^='col-'] {
      flex: 0 0 50%;
      max-width: 50%;
      // + div {
      //     margin-left: 50%;
      // }
    }
  }

  ul {
    margin-bottom: 32px;

    li {
      padding: 0 0 8px 32px;
      font-weight: $font-weight-base;
      color: $dark;
      font-size: $font-size-base;
      line-height: 32px;

      &:last-child {
        padding-bottom: 0;
      }

      // }
      &:before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        // border: 2px solid $primary;
        border-radius: 50%;
        background: $secondary icon(icon-checkmark, $white) center center no-repeat;
        background-size: 10px;
        top: 6px;
        left: 0;
      }
    }
  }

  .attribute_logos {
    picture {
      padding: 16px;
      width: 112px;
      height: 84px;
      position: relative;
      display: inline-flex;
      background-color: $white;
      min-height: auto;
      margin-right: 8px;
      @include media-breakpoint-up(lg) {
        margin-right: 16px;
      }

      img {
        max-width: 100%;
        height: auto;
        display: block;
        position: relative;
      }
    }

    span {
      display: none;
    }
  }

  .data-tag {
    border-top: 2px solid $white;
    padding-top: 24px;

    p {
      color: $primary;
      margin-bottom: 16px;
      font-weight: $font-weight-bold;
    }

    .btn {
      height: 32px;
      padding: 2px 16px 6px;
      margin-right: 8px;
      margin-bottom: 8px;
      line-height: 24px;
      font-weight: $font-weight-bold;
    }
  }
}

// Online kaufen modal Fenster
// -------------------------------------------------------------------------
.CC_FI_887GE12_btn.CC_FI_887GE12_btn-blue {
  background-color: $primary !important;
  height: 56px;
  border-radius: 28px;
  display: inline-block;
  font-weight: 900;
  // color: #212529;
  text-align: center;
  vertical-align: middle;
  // border: 2px solid transparent;
  padding: 8.5px 32px;
  font-family: $font-family-sans-serif;
  font-size: 1.125rem;
  line-height: 28px;
  transition: $transition-base;
  border: 0;
  color: #fff;

  &:hover {
    background-color: $link-hover-color !important;
    transition: $transition-base;
  }
}

.CC_BuyBtn1 {
  margin: 32px 0 !important;
  display: inline-block;
  @include media-breakpoint-up(lg) {
    margin: 56px 0 !important;
  }
}

.CC_FI_887GE12_Black_overlay {
  background-color: $primary !important;
  opacity: .45 !important;
}

.woocommerce div.product div.images .woocommerce-product-gallery__trigger {
  display: none;
}

.woocommerce-product-gallery.woocommerce-product-gallery--with-images.woocommerce-product-gallery--columns-4.images {
  max-height: 520px;
  width: 100% !important;
  max-width: 520px;
  @include media-breakpoint-down(sm) {
    height: auto !important;

  }
}

.woocommerce .woocommerce-product-rating .star-rating {
  width: 80px;
  margin-right: 8px;
}

.woocommerce .star-rating {
  &:before {
    content: '';
    background: icon(icon-rating-passive, $secondary) center center no-repeat;
    height: 16px;
    width: 80px;
  }

  span::before {
    content: '';
    background: icon(icon-rating-active, $secondary) center center no-repeat;
    height: 16px;
    width: 80px;
  }
}

.woocommerce-product-details__short-description {
  p {
    font-weight: $font-weight-bold;
    color: $dark;
  }
}

.woocommerce div.product p.price {
  display: none;
}


.woocommerce-product-gallery {
  &.fixed {
    @include media-breakpoint-up(xl) {
      figure {
        position: fixed !important;
        transition: all .5s ease;
        transition: none !important;
        // width: 100%;
        top: 64px;
        //max-height: 520px;
        a {
          max-height: 520px;
          max-width: 520px;
        }
      }
      .zoomImg {
        display: none !important;
      }
    }
    @media (min-width: 1200px) and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      figure {
        left: calc(((100vw - 1200px) / 2) + ((708px - 520px) / 2));
      }
    }
    @media (min-width: 1464px) and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      figure {
        left: calc(((100vw - 1440px) / 2) + ((708px - 520px) / 2) - 12px);
      }
    }
  }
}
